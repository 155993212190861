<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;">{{location.name || ''}} - Location</h4>
    <div class="location-card">
      
      <div class="card-body">
        <img v-if="location.src" class="card-image" :src="location.src">
        <img v-else class="card-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
        <div>
        <label>Description: </label>
        {{location.description}}
        <!--  -->
        <!-- <label>Apperance</label> -->
        {{location.apperance}}
        <!--  -->
        <!-- <label>Personality</label> -->
        {{location.personality}}
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          location: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                updatinglocation: false,
              }
            },
              methods: {
                
        }
      }
</script>