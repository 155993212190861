<template>
  <div class="about container">
     <img style="z-index: 0; left:0; width: 100%; position: absolute;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
     <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
     <nav class="navbar-fixed-top navbar fixed-top navbar-expand-lg navbar-top justify-content-center" >
      <a class="navbar-brand" href="/main" style="font-size: 14px;">
    <!-- <img src="/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    <div class='pocket-brand-wrap ' style="width:20px; height:20px; float:left; border-radius: 3px; margin-right:4px; ">
      <div style=" padding:1px;"><i class="ra ra-perspective-dice-random" style="font-size: 14px;"></i></div></div>
    Pocket RPG
  </a>
    </nav>
      </div>
    <div class="row justify-content-md-center" style="margin-top:60px; z-index: 1;" >
      <div class="col-xs-12 ">
    <h1 class="outside-white">Pocket RPG</h1>
  </div>
</div>
    <div class="row justify-content-md-center" >
      <div class="col-s-12 col-md-12 col-lg--6">
        <div class="card start-card" >
          <!-- <img class="card-img-top" :src="`${publicPath}floating_city.jpg`" alt="Card image cap"> -->
          <!-- Old Wizard remove -->
          <!-- <img class="card-img-top" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/A%20wizard%20standing%20in.jpg" alt="Card image cap"> -->
          
          <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
            <h5 class="card-title">Pocket RPG: Welcome</h5>
<p class="card-text" style="text-align:left;"> Pocket RPG is a collaborative role playing and story telling app.You can create characters and share them with friends. After creating a character there is an invite button at the top that you can send to a friend to play.</p>
            
            
<!-- -->
            <div v-if="changeName=='np'"   class="input-group mb-3">
              {{currentUser.displayName}}
          <div class="input-group-prepend">
            <!-- <span class="input-group-text" id="basic-addon1">@</span> -->
          </div>
           <label class="label-helper" for="name">User Name</label>
          <input id="name" type="text"  class="form-control" v-on:keyup="updateUsername" v-model.trim="UserForm.name"  placeholder="Username "  aria-label="Character" aria-describedby="basic-addon1">
        </div>
        <div>
        <router-link v-if="currentUser.displayName !=''" to="/create_character" class="btn btn-primary btn-sm">Start a New Adventure</router-link> 
        <a v-if="currentUser.displayName !=''" style="margin-left:10px" href="" v-on:click.prevent="toggleCharacters()" class="btn btn-secondary btn-sm">Toggle Characters and DM</a>
      </div>

          </div>
        </div>
      </div>
      </div>

      <div class="row justify-content-md-center" >
        <div class="col-xs-12 col-md-6" >
          <h4 v-if="!stateDM" style="color:white;">{{currentUser.displayName}}'s Heros</h4>
          <h4 v-else style="color:white;">Following other Heros & DM</h4>
        </div>
      </div>
      <div class="row justify-content-left" >
        <!-- <div class="col-xs-12 col-md-6 card-group"> -->
        <div v-for="character in character_list" v-bind:key='character' class="col-xs-4 col-md-4">
          <div  class="card character-card" >
            <img class="card-img-top" src="/" alt="Card image cap">
            <img :src="characterCurrentSceneImage(character)" alt="Card image cap" class="card-img-top" style="height:100%;">
            <div class="card-body" style="background: rgba(2,2,1,.6); color: white;">
              <h5 class="card-title shimmer" style='margin-top:20px; text-align: left;'>
                <router-link v-if="!stateDM" :to="'character/'+character.id" style="color:white; text-decoration:none;">
                  <div class="small-image" style="float:left;">
                  <img v-if="character.src" class="" :src="character.src" :style="characterImage(character.src)">
                  <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png"></div><div style="float:left;">{{ character.name }}</div>
                </router-link>
                <router-link v-else :to="'character/'+character.id+'?gm=true'" style="color:white; text-decoration:none;">
                <div class="small-image" style="float:left;">
                <img v-if="character.src" class=""  :src="character.src" :style="characterImage(character.src)">
                  <img v-else  class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
                </div>
                  <div style="float:left;">{{ character.name }}</div>
                </router-link>
              </h5>
              <!-- <span>{{ character.createdOn}}</span> -->
              <!-- <p>{{character.description}}</p> -->
              <div class="quest-summary" ><p class="font-weight-normal" style="font-size: 14px;">{{ character.quest }}</p></div>
              <p> 
                <router-link v-if="!stateDM" :to="'character/'+character.id" style="color:white; text-decoration:none;"><span class="oi oi-comment-square space-icon"></span> Continue The Tale</router-link>
                <router-link v-else :to="'character/'+character.id+'?gm=true'" style="color:white; text-decoration:none;"><span class="oi oi-comment-square space-icon"></span> Read The Tale</router-link>
                <!-- <a :href="'character/'+character.id" style="color:white; text-decoration:none;"><span class="oi oi-comment-square space-icon"></span> Continue The Tale</a> -->
              </p>
            <!-- </div> -->
          </div>
        </div>
      </div>
        <div class="col-xs-12">
            <!-- <p class="no-results">Currently you dont have any characters.</p> -->
        </div>
      </div>
    </div>
</template>
<script>
  import { mapState } from 'vuex'

  import moment from 'moment'
  const fb = require('../firebaseConfig.js')

  export default {
    data() {
      return {
        changeName: 'no',
        stateDM: false,
        UserForm: {
          name : this.$store.state.currentUser.displayName
        },
        publicPath: process.env.BASE_URL,
        followingCharacters: [],
        character_list: [],
      }
    },
    computed: {
      ...mapState(['userProfile', 'currentUser', 'characters_list'])
    },
    beforeMount(){
      this.loadCharacters()
    },
    methods:{
      characterImage(src){
      var response = '';
      var calc = {'y':0,'x':0,'height':0,'width':0}
      var pop = src.split('?')
      pop = pop[1]
      pop = pop.split('&')
      pop.forEach(function(pair) {
        var key = pair.split('=')[0];
        var value = pair.split('=')[1];
        calc[key] = value;
      });
      response = 'position:absolute; left:-'+calc['x']+'px; top:-'+calc['y']+'px; height:'+Math.round(calc['height'])+'px; width:'+Math.round(calc['width'])+'px;';
      return response
    },
      characterCurrentSceneImage(character){
        var type = ''
        try{
          type = character.events[character.events.length -1].type;  
        }catch{
          console.log('failed')
        }
        if(type !=''){
          return this.eventImage(type)
        }else{
          return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png'
        }
      },
      eventImage(type){
      var clean_type = new String(type).toLowerCase();
      if(clean_type=='start'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
      }
      if(clean_type=='exploration'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Dungeon.jpg'
      }
      if(clean_type=='creature'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Creature.jpg'
      }
      if(clean_type=='boss' || clean_type=='nemisis'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Generate%20an%20image%20of.jpg'
      }
      return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
    },
      toggleCharacters(){
        if(this.stateDM){
          this.loadCharacters()
        }else{
          this.getFollowingCharacters()
        }
        this.stateDM = !this.stateDM

      },
      updateUsername :function(event){

        var name = this.UserForm.name;
        // console.log(this.UserForm.name);
        console.log(this.$store.state.currentUser)
        if(event.keyCode === 13){
          console.log("enter key pressed")
          console.log(this.$store.state.currentUser.uid)
        var user = fb.auth.currentUser;
         user.updateProfile({
            displayName: name
          }).then(function() {
            // Update successful.
            console.log('successful')
          }).catch(function(error) {
            console.log('an error occured')
            console.log(error)
          });

      }
    },
    getFollowingCharacters: function(){
      var follows = this.$store.state.userProfile.characterFollows;
      fb.charactersCollection.where('__name__', 'in' ,follows).onSnapshot(querySnapshot => {
            // check if created by currentUser
            //maybe breaking
            // var createdByCurrentUser = ''
            // var changes = querySnapshot.docChanges();
            // if (querySnapshot.docs.length) {
            //     createdByCurrentUser = this.store.state.currentUser.uid == changes[0].doc.data().userId ? true : false
            // }
                let charactersArray = []

                querySnapshot.forEach(doc => {
                    let character= doc.data()
                    character.id = doc.id
                    charactersArray.push(character)
                })

                this.character_list = charactersArray;
        })

    },
    loadCharacters: function(){
      var user = this.currentUser
      fb.charactersCollection.where('userId','==',user.uid).orderBy('createdOn', 'desc').onSnapshot(querySnapshot => {
          // check if created by currentUser
          //maybe breaking
          // var createdByCurrentUser = ''
          // var changes = querySnapshot.docChanges();
          // if (querySnapshot.docs.length) {
          //     createdByCurrentUser = this.store.state.currentUser.uid == changes[0].doc.data().userId ? true : false
          // }
          // console.log(createdByCurrentUser)
          // console.log('try deleting createdByCurrentUser')
          // console.log(user)
          
              let charactersArray = []

              querySnapshot.forEach(doc => {
                  let character= doc.data()
                  character.id = doc.id
                  charactersArray.push(character)
              })
              this.character_list  = charactersArray
              // this.store.commit('setCharacters', charactersArray)
      })
    }
  },
    filters: {
      formatDate(val) {
        if (!val) { return '-' }
          let date = val.toDate()
        return moment(date).fromNow()
      },
      trimLength(val) {
        if (val.length < 200) { return val }
          return `${val.substring(0, 200)}...`
      }
    }
  }
</script>