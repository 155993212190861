<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;" v-on:click.prevent="toggleMarquee()"><img v-if="faction.src" class="small-image" :src="faction.src">
        <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Generate%20fantasy%20con.jpg">{{faction.name || ''}} - Faction</h4>
    <div class="faction-card">
      
      <div class="card-body card-info">
        <div v-if="showMarquee">
        <img v-if="faction.src" class="card-image" :src="faction.src">
        <img v-else class="card-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Generate%20fantasy%20con.jpg" alt="Card image cap">
        </div>
        <div>
        <br><br>
        <h6>Description </h6>
        <hr>
        {{faction.description}}
        <br><br>
        <h6>Background</h6>
        <hr>
        {{faction.background}}
        <br><br>
        <h6>Goals</h6>
        <hr>
        {{faction.main_motivation}}
        <br><br>
        <h6>Leader</h6>
        <hr>
        {{faction.leader}}
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          faction: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                updatingfaction: false,
                showMarquee:false
              }
            },
              methods: {
                 toggleMarquee(){
            this.showMarquee = !this.showMarquee;
          }
        }
      }
</script>