<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;" v-on:click.prevent="toggleMarquee()"><img v-if="item.src" class="small-image" :src="item.src">
        <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered%20Lands%20Items.png">{{item.name || ''}} - item</h4>
    <div class="item-card">
      
      <div class="card-body card-info">
        <div v-if="showMarquee">
        <img v-if="item.src" class="card-image" :src="item.src">
        <img v-else class="card-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered%20Lands%20Items.png" alt="Card image cap">
        </div>
        <div>
        <br>
        <h6>Description </h6><hr>
        <div style="text-align: left;">{{item.description}}</div>
<!--         <br><br>
        <h6>Apperance</h6><hr>
        <div style="text-align: left;">{{item.appearance}}</div>
        <br><br>
        <h6>Personality</h6><hr>
        <div style="text-align: left;">{{item.personality}}</div> -->
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          item: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                updatingitem: false,
                showMarquee: false
              }
            },
              methods: {
                 toggleMarquee(){
                  this.showMarquee = !this.showMarquee;
        }
      }
    }
</script>