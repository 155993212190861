<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;" v-on:click.prevent="toggleMarquee()">
        <img v-if="skill.src" class="small-image" :src="skill.src">
        <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered%20Lands%20Items.png">
      {{skill.name || ''}} - skill</h4>
    <div class="item-card">
      
      <div class="card-body card-info">
        <div v-if="showMarquee">
        <img v-if="skill.src" class="card-image" :src="skill.src">
        <img v-else class="card-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered%20Lands%20Items.png" alt="Card image cap">
        </div>
        <div>
        <br>
        <h6>Description </h6><hr>
        <div style="text-align: left;">{{skill.description}}</div>
        <br><br>
        <h6>History </h6><hr>
        <div style="text-align: left;">{{skill.master_user}}</div>
<!--         <br><br>
        <h6>Apperance</h6><hr>
        <div style="text-align: left;">{{skill.appearance}}</div>
        <br><br>
        <h6>Personality</h6><hr>
        <div style="text-align: left;">{{skill.personality}}</div> -->
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          skill: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                updatingskill: false,
                showMarquee: false
              }
            },
              methods: {
                 toggleMarquee(){
                  this.showMarquee = !this.showMarquee;
        }
      }
    }
</script>