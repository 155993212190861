<template>
    <div class="col-xs-12 col-md-4" style="margin-bottom: 60px;">
        <div class='card'>

          <dl class="row ">
            <dd class="col-sm-12 " style="text-align: left;">
              <div class="p-3 time_date black">
                <h4>Instructions</h4>
                <a href='https://docs.google.com/document/d/1nQRw5nxChudPMTxim4_I7mpRQKgMQZ9FCZENsUJnf-E/edit?usp=sharing'>More Detailed Instructions</a>
                <br><br>
                <h6>Getting Started: Towns and Cities <i class="ra  ra-castle-emblem"></i></h6>
                <hr/>
                Each <b>Card</b> is a scene in your story. You start out in a town or city. Search for <b>Rumors</b> from the locals for potential quests to show your worth. Try and recruit an ally, get an item, or gain more info on the rumors while you are in town. You get a few attempts in a town to influence things in your favor. Once your done in town you can click end scene to choose where to go next. Checkout the <b>Quest Page</b> if you need a refresher on anything.
                <br>
                <br>
                If <b><i class="ra  ra-helmet"></i> Dungeon Buddy</b> is on, <b>Rumors</b> will be generated for you. If Dungeon buddy is off, you need a friend to make a rumor or two.
                <br>
                <br>
                <br>
                <br>
                <h6>Exploring: Pick a Rumor <i class="ra  ra-x-mark"></i></h6>
                <hr/>
                After you are done in town, click <b>End Scene</b> at which point there will be a success or fail roll. In the <b>Quest</b> section click on the <b>Rumor</b> your want to pursue and when the current scene is over a <b>Card</b> will be generated for the next scene with that rumor. Be wary though, as you venture out of the towns and cities and continue on your journey things will become more difficult.

                <br>
                <br>
                <br>
                <br>
                <h6>Growing the World <i class="ra  ra-compass"></i></h6>
                <hr/>
                <span>Its up to you to progress the game, but the more you ask questions about existing rumors, factions, items and NPCs the better off or more informed your character is for the end of the scene. You can also add things to the world, such as NPCs, Items, Locations, and such.</span>
                <br>
                <br>
                <span class="time_date">"Tell me more about the Dark Castle."</span>
                <br>
                <br>
                <br>
                <h6>Adding things to the world.</h6>
                Click on the <b>Add to Scene</b> button to add text as your character. Below are the example commands to add Items, Locations, NPCs, and Factions. You don't have to add them all in one line. Anything you add will create the thing to be tracked in your <b>Quest</b> log.
                <hr/>
                <img style="width:100%; margin:20px 0" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Screenshot%202024-02-09%20at%205.04.41%20PM.png">
                <h6>Doom <i class="ra ra-player-thunder-struck"></i> and Lore <i class="ra ra-book"></i></h6>
                <hr/>
                <span >Everytime you add to the scene your <b>Doom</b> goes up making it harder. But at the same time if you discover secrets or take a great action your <b>Lore</b> goes up helping you.</span>
                <br>
                <br>
                <h6>The End <i class="ra  ra-player-shot"></i></h6>
                <hr/>
                <span >Your character needs closure, it could be in defeating a creature, finding a missing scroll, or whatever your main quest is. Theres always lucked invovled and remember the more you take on the more challenging things become.</span>
                <br>
                <br>

            </div>
        </dd>
    </dl>
</div>
</div>
</template>