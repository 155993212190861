// import Vue from 'vue'
import { createStore } from 'vuex'
const fb = require('../firebaseConfig.js')

// Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
    if (user) {
        console.log('auth state change')
        store.commit('setCurrentUser', user)
        // store.dispatch('fetchUserProfile')

        fb.usersCollection.doc(user.uid).onSnapshot(doc => {
            store.commit('setUserProfile', doc.data())
        })
        // console.log(state.userProfile)

        // realtime updates from our characters collection
    }
})

export const store = createStore({
    state: {
        currentUser: null,
        userProfile: {},
        characters_list: []
    },
    actions: {
        clearData({ commit }) {
            commit('setCurrentUser', null)
            commit('setUserProfile', {})
            commit('setCharacters', null)
        },
        fetchUserProfile({ commit, state }) {
            fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
                commit('setUserProfile', res.data())
            }).catch(err => {
                console.log(err)
            })
        },
        updateProfile({ commit, state }, data) {
            console.log(commit)
            let name = data.name
            let title = data.title

            fb.usersCollection.doc(state.currentUser.uid).update({ name, title }).then(user => {
                // update all posts by user to reflect new name
                console.log(user)
                fb.charactersCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
                    docs.forEach(doc => {
                        fb.charactersCollection.doc(doc.id).update({
                            userName: name
                        })
                    })
                })
                // update all comments by user to reflect new name
                fb.commentsCollection.where('userId', '==', state.currentUser.uid).get().then(docs => {
                    docs.forEach(doc => {
                        fb.commentsCollection.doc(doc.id).update({
                            userName: name
                        })
                    })
                })
            }).catch(err => {
                console.log(err)
            })
        }
    },
    mutations: {
        setCurrentUser(state, val) {
            state.currentUser = val
        },
        setUserProfile(state, val) {
            state.userProfile = val
        },
        setCharacters(state, val) {
            // console.log('setCharacters')
            // console.log(val)
            if (val) {
                state.characters_list = val
            } else {
                state.characters_list = []
            }
            // console.log(state.characters)
        }
    }
})