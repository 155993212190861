<template> 
 <div class="col-xs-12 col-md-4">
    <div class='card' style="margin-bottom: 60px;">
        <div class="card-body">
    <h4 style="margin-top: 20px;">Campaign Setting</h4>
    <div v-if="mapView=='global'" class="row">
        <div class="col-sm-12">
    <div  style="padding:10px; width:400px; height:400px;">
    <l-map ref="map" v-model:zoom="zoom" :min-zoom="minZoom" :center="[500, 500]" :options="mapOptions" :crs="crs">
    <!--   <l-tile-layer
        url="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/shatteredlands_global_map.png"
        layer-type="base"
        name="OpenStreetMap"
      ></l-tile-layer> -->
      <l-image-overlay
        :url="url"
        :bounds="bounds"
      />
    </l-map>
  </div>
  </div>
    </div>
    <div v-if="mapView=='setting'" class="" style="font-size: 14px;">
    <img style="width: 100%; margin-bottom: 20px;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/CastleIntro.jpg">
    <h5 class="card-title">Overview</h5>
     <p style="text-align:left;">Welcome to the <b>Shattered Lands</b>,<br> What was once known as Hallamar are now called the <b>Shattered Lands</b>. The Great Reckoning created a line between the creatures of magic and darkness and the world of the light. It is said that there was once great magic throughout the kingdom but it was sucked into the Shattered Lands right before the Reckoning. Now magic can only be created with Fell Stones that are brought back from the lands. The Warlocks, warrior monks chosen from the kingdom lead expeditions into the lands and act as shepards for the Houses of Hallamar.
     </p>
     <p  style="text-align:left;">The Great Houses of Hallamar send heros into the Shattered Lands to discover secrets and to compete for power. <br><br>Good Luck Exploring!</p>
    </div>
      <div v-if="mapView!=='global'" class="row">
        <div class="col-sm-12">
        <!-- <img  class="" style="padding:10px; width: 100%;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/shatteredlands_global_map.png" alt="Card image cap"> -->
        <img v-if="mapView=='local'" class="" style="padding:10px; width: 100%;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Starting%20Region.png" alt="Card image cap">
        <img v-if="mapView=='city'" class="" style="padding:10px; width: 100%;" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/mecia.png" alt="Card image cap">
        
     </div>
    </div>
    <div class="row">
    <div class="col-sm-12">
        <a  href='#' @click.prevent="toggleMapView('setting')" class="btn btn-primary btn-sm" button>Setting</a>
        <a  href='#' @click.prevent="toggleMapView('city')" class="btn btn-outline-primary btn-sm" button>City Map</a>
        <a  href='#' @click.prevent="toggleMapView('local')" class="btn btn-outline-primary btn-sm" button>Local Map</a>
        <a  href='#' @click.prevent="toggleMapView('global')" class="btn btn-outline-primary btn-sm" button>Global Map</a>
    </div>
</div>
  </div>
</div>
</div>
</template>


<script>
import "leaflet/dist/leaflet.css";
import { CRS } from "leaflet";
import { LMap, LImageOverlay } from "@vue-leaflet/vue-leaflet";
// { attributionControl:false }
    export default {
        components: {
            LMap,
            LImageOverlay,
        },
       props: {
          map: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                zoom:-3,
                minZoom:-1.5,
                crs: CRS.Simple,
                bounds: [[0, 0], [1000, 1000]],
                url: 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/shatteredlands_global_map.png',
                mapView: 'setting',
                 mapOptions: {
                    attributionControl:false
                  },
              }
            },
              methods: {
              toggleMapView(view){
              this.mapView = view
              }
                
        }
      }
</script>