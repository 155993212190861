<template>
  <div class="character_section clearfix">
    <div class="row justify-content-md-center " style='margin-bottom: 8px;'>
     <nav class="navbar-fixed-top navbar fixed-top navbar-expand-lg navbar-top justify-content-center" >
      <!-- <router-link to="/main"></router-link>  -->
      <router-link class="navbar-brand" to="/main" style="font-size: 14px; font-weight: 500;">
    <!-- <img src="/docs/4.0/assets/brand/bootstrap-solid.svg" width="30" height="30" class="d-inline-block align-top" alt=""> -->
    <div class='pocket-brand-wrap ' style="width:20px; height:20px; float:left; border-radius: 3px; margin-right:4px; ">
      <div style="padding:1px;"><i class="ra ra-perspective-dice-random" style="font-size: 14px;"></i></div></div>
    Pocket RPG
  </router-link>
  <!-- <a v-if="isPlayer()" class="navbar-brand-top" href="?gm=True#" v-on:click.prevent="clearActive('npcs')" target="_blank"><i class="bi bi-share"></i> Test Button</a> -->
   <div v-if="$route.query.gm" style="margin-left:10px; font-size:14px;">GM: {{character.name}}</div>
    <div v-else style="margin-left:10px; font-size:14px; ">{{character.name}}</div>
  <div class="dropdown dropstart" style="margin-left: 20px;">
  <div class="nav-item dropdown">
          <a class="nav-link" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false" style="font-size: 16px;">
            <i :class='"ra  ra-helmet "+dungeonBuddyChat' ></i>...
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><a v-if="isPlayer()" class="dropdown-item" href="?gm=True#" v-on:click.prevent="copyClipboard()" target="_blank"><i class="bi bi-share"></i> Invite</a></li>
            <li><a v-if="isPlayer()" class="dropdown-item" href="?gm=True#" v-on:click.prevent="feedbackOpen()" target="_blank"><i class="ra ra-speech-bubble"></i> Feedback</a></li>
            <li><hr class="dropdown-divider"></li>
            <!-- <li><a href='#' @click.prevent="toggleDungeonBuddyModal()" class="drop" button> <i :class='"ra  ra-helmet "+dungeonBuddyChat'></i> Dungeon Buddy </a></li> -->
            <li><a class="dropdown-item" v-on:click="toggleDungeonBuddyChat()" ref="el" type="button"><i :class='"ra  ra-helmet "+dungeonBuddyChat' ></i> Dungeon Buddy <span v-if="dungeonBuddyChat">Enabled</span><span v-else>Disabled</span></a></li>
            <li><a class="dropdown-item" v-on:click="deleteCharacter()" ref="el" type="button">Delete Character <span v-if="deleteCharacterCheck">Confirm</span></a></li>
            <li><a v-if="!isPlayer() && !isFollowing()" class="dropdown-item" href="?gm=True#" v-on:click.prevent="addGM()" target="_blank"><i class="bi bi-share"></i> GM Follow</a></li>
            <li><a v-if="!isPlayer() && isFollowing()" class="dropdown-item" href="?gm=True" ><i class="bi bi-share"></i> GM Mode</a></li>
          </div>
        </div>
</div>
      <!-- <a class="navbar-brand-top" href="/main"><i class="ra ra-castle-emblem"></i> Home</a> -->
      <!-- <a class="navbar-brand" href="#" v-on:click="toggleSubView('choice')">Current</a> -->
      <!-- <a class="navbar-brand" href="#" v-on:click="toggleSubView('status')"><i class="ra ra-player-pain"></i> Status</a> -->
      <!-- <a class="navbar-brand" href="#" v-on:click="toggleSubView('history')"><i class="ra ra-scroll-unfurled"></i> Journey</a> -->
      
    </nav>
    <nav class="navbar-fixed-bottom navbar fixed-bottom navbar-expand-lg justify-content-center" >

      <a class="navbar-brand-bottom" href="#" :class="{'active' : view=='current'}" v-on:click.prevent="toggleSubView('current')"><i class="ra ra-clockwork"></i> <div class='nav-logo-text'>current</div></a>
      <a class="navbar-brand-bottom" href="#" :class="{'active' : view=='history'}" v-on:click.prevent="toggleSubView('history')"><i class="ra ra-book"></i> <div class='nav-logo-text' >history</div></a>
      <!-- <a class="navbar-brand-bottom" href="#" v-on:click="toggleSubView('status')"><i class="ra ra-player-pain"></i>
        <div class='nav-logo-text'> status</div></a> -->
        <a class="navbar-brand-bottom" :class="{'active' : view=='quest'}" href="#" v-on:click.prevent="toggleSubView('quest')"><i class="ra ra-trophy"></i>
          <div class='nav-logo-text'> quest</div>
        </a>
        <a class="navbar-brand-bottom" :class="{'active' : view=='instructions'}" href="#" v-on:click.prevent="toggleSubView('instructions')"><i class="ra ra-uncertainty"></i>
          <div class='nav-logo-text'> instructions</div></a>
          <a class="navbar-brand-bottom" :class="{'active' : view=='map'}" href="#" v-on:click="toggleSubView('map')"><i class="ra ra-compass"></i>
          <div class='nav-logo-text'> campaign</div></a>
          <!-- Remove Location Section -->
          <!--  <a class="navbar-brand-bottom" href="#" v-on:click="toggleSubView('location')"><i class="ra ra-wooden-sign"></i> <div class='nav-logo-text'>location</div></a> -->

        </nav>
      </div>

      <!-- QUEST VIEWS, NPC, Creature, location, items, faction -->
      <div class='clearfix' style='margin-top:60px; margin-bottom: 60px;'>
        <div v-if="view ==='map'" class='row justify-content-md-center map' style='margin-top: 20px;'>
        <MainMap :location="viewLocation"></MainMap>
        </div>

        <div v-if="view=='instructions'" class="row justify-content-md-center location_row" >
          <SingleInstructions></SingleInstructions>
          </div>
    <div v-if="view=='npc'" class="row justify-content-md-center big_choice" style=''>
      <NPC :npc="viewNPC"></NPC>
    </div>
     <div v-if="view=='creature'" class="row justify-content-md-center big_choice" style=''>
      <SingleCreature :creature="viewCreature"></SingleCreature>
    </div>
     <div v-if="view=='location'" class="row justify-content-md-center big_choice" style=''>
      <SingleLocation :location="viewLocation"></SingleLocation>
    </div>
     <div v-if="view=='faction'" class="row justify-content-md-center big_choice" style=''>
      <SingleFaction :faction="viewFaction"></SingleFaction>
    </div>
    <div v-if="view=='item'" class="row justify-content-md-center big_choice" style=''>
      <SingleItem :item="viewItem"></SingleItem>
    </div>
    <div v-if="view=='skill'" class="row justify-content-md-center big_choice" style=''>
      <SingleSkill :skill="viewSkill"></SingleSkill>
    </div>

    <!-- QuEST CARD -->
    <div v-if="view=='quest'" class="row justify-content-md-center big_choice" style=''>
     
      <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
         <div class="card-body card-info">
        <h4  v-on:click.prevent="toggleMarquee()" style="margin-top: 20px;">
        <div class="small-image">
        <img v-if="character.src"  :src="character.src" :style="characterImage(character.src)">
        <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png">
        </div>
        {{character.name}} 
        </h4>
        <dl class="row">
          <dd class="col-sm-12 "><div style="float:left;"><b>{{character.class}}</b></div><div style="margin-left:10px; float:left;"><span v-if="character.doom">Doom <i class="ra ra-focused-lightning"></i> {{character.doom}}</span>
                <span v-else >Doom <i class="ra ra-focused-lightning"></i> 0</span></div>
                <div  style="margin-left:10px; float:left;"><span v-if="character.lore">Lore <i class="ra ra-sun-symbol"></i> {{character.lore}}</span>
                <span v-else >Lore <i class="ra ra-sun-symbol"></i> 0</span></div></dd>
        </dl>
        <div v-if="showMarquee" class="marquee">
          <img v-if="character.src" v-on:click.prevent="setCharacter" class="card-image" :src="character.src">
          <img v-else class="card-image" v-on:click.prevent="setCharacter" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg" alt="Card image cap">
        </div>
        <dl class="row">
          <dt class="col-sm-12">Description</dt>
          <hr>
          <dd class="col-sm-12 ">{{character.description}}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Epic Motivation</dt><hr>
          <dd class="col-sm-12">{{character.epic_motivation}}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Trivial Motivation</dt><hr>
          <dd class="col-sm-12">{{character.trivial_motivation}}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Hates</dt><hr>
          <dd class="col-sm-12">{{character.hates}}</dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Skills <i class="ra  ra-trophy"></i></dt><hr>
          <dd class="col-sm-12">
            <div class="more_info" v-for="skill in character.skills"  v-bind:key='skill' style="clear:both;">
              <div class="" v-on:click.prevent="toggleSubViewObject('skill',skill)"><span v-if="skill.name">{{skill.name}}</span><span v-else>Unknown Item</span></div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">NPCs <i class="ra  ra-helmet"></i></dt><hr>
          <dd class="col-sm-12">
            <div class="" v-for="npc in character.npcs"  v-bind:key='npc' style="clear:both;">
              <div class="more_info" v-on:click.prevent="toggleSubViewObject('npc',npc)">
                 <span v-if="npc && npc.lore">{{npc.name}}</span><span v-else>Uknown NPC</span></div>
                <div v-if="npc.status" class='status'>{{relationPrint(npc.status)}}</div>
                <div v-else style="font-size:8px;">neutral</div>
                <div v-if="npc.active && npc.active=='true'" class='status text-success'>Active</div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Creatures <i class="ra  ra-hydra"></i></dt><hr>
          <dd class="col-sm-12">
            <div class="" v-for="creature in character.creatures"  v-bind:key='creature' style="clear:both;">
              <div class="more_info" v-on:click.prevent="toggleSubViewObject('creature',creature)">
                 <span v-if="creature.lore">{{creature.name}}</span><span v-else>Uknown Creature</span></div>
                <div v-if="creature.status" class='status'>{{relationPrint(creature.status)}}</div>
                <div v-else style="font-size:8px;">neutral</div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Factions <i class="ra  ra-crown"></i></dt><hr>
          <dd class="col-sm-12">
            <div class="more_info" v-for="faction in character.factions"  v-bind:key='faction' style="clear:both;">
              <div class="" v-on:click.prevent="toggleSubViewObject('faction',faction)"> <span v-if="faction.lore">{{faction.name}}</span><span v-else>Unkown Faction</span></div> 
              <div v-if="faction.status" class='status'>{{statusPrint(faction.status)}}</div>
              <div v-else class='status' style="font-size:8px;">neutral</div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Items <i class="ra  ra-torch"></i></dt><hr>
          <dd class="col-sm-12">
            <div class="more_info" v-for="item in character.items"  v-bind:key='item' style="clear:both;">
              <div class="" v-on:click.prevent="toggleSubViewObject('item',item)"><span v-if="item.lore">{{item.name}}</span><span v-else>Unknown Item</span></div> <div v-if="item.status">status: {{faction.status}}</div>
            </div>
          </dd>
        </dl>
        <dl class="row">
          <dt class="col-sm-12">Locations <i class="ra  ra-pyramids"></i></dt><hr>
          <dd class="col-sm-12">
            <div class="more_info" v-for="location in character.locations"  v-bind:key='location' style="clear:both;">
              <div class="" v-on:click.prevent="toggleSubViewObject('location',location)"> {{location.name}}</div>
              <div style="font-size:8px;">not explored</div>
            </div>
          </dd>
        </dl>
          <dl class="row">
          <dt class="col-sm-12">Rumors <i class="ra  ra-x-mark" ></i></dt><hr>
          <dd class="col-sm-12">
            <div class="" v-for="(encounter,index) in character.encounters"  v-bind:key='encounter' style="clear:both;">
              <div class="rumor" :class="completedEncounter(encounter)">
                <span v-if="encounter.rumor" v-on:click.prevent="selectNextEncounter(index)" :class="[selectedNextEncounter(encounter),completedEncounter(encounter)]">
                  <i class="ra  ra-x-mark" ></i> {{encounter.rumor}}</span>
                <span v-else>Unknown Rumor</span>
              </div> <div v-if="encounter.status">status: {{encounter.status}}</div>
            </div>
          </dd>
        </dl>
      </div>
    </div>
    </div>
    <div v-if="view ==='history' || view ==='current'" class='row justify-content-md-center history' style='margin-top: 20px;'>
      <!-- THis needs to be nested properly -->
      <!-- && typeof(event.result)=='undefined' -->
      <div class="col-xs-10 col-md-4 col-12" style="margin-bottom: 60px;" v-if="view=='history' || view=='current'">
        <!-- removed class -->
        <div v-for="event in events" v-bind:key='event'   style=''  >
          <!-- event -->
          <!-- v-if="!event.attempt && view=='current'" -->
          <div class="card" v-if="view=='current' || view=='history'">
            <!-- v-on:click="toggleSubView('choice')" removing -->
            <div class="incoming_msg" >
              <span class="time_date"><span v-if="event && event.location">{{event.location.name}}</span><span v-else>{{location.name}}</span> - {{time_convertor(event.gameTime)}} </span>
            </div>
            <div class="incoming_msg_img"> 
              <!-- <img src="https://ptetutorials.com/images/user-profile.png" alt="sunil">  -->
            </div>
            <div class='banter'>
              <transition name="fade">
                <!-- Old -->
                <!-- v-if="viewEventComments"  -->
                <div  class="p-modal">
                  <div class="p-container">

                    <div class="post">
                     <div class="post_top">
                      <!-- <i class="ra ra-helmet"></i>  -->
                      <div class="post_top white-background">
                        <img style="width:100%;" :src="eventImage(event.type)">
                        <p style='border-bottom solid 1px #DDD; margin-bottom: 4px; font-weight:400;'>
                          <!--   <span class='' style='font-size: 8px; color:#DDD;'>Foreshadowing...<br></span> -->
                        {{event.description}} </p>
                        <!-- Event image -->
                        <a v-if="event.attempt && showEventComments !== event.createdOn" href='#' @click.prevent="viewEventComments(character,event)" class='banter' button><span class="oi oi-comment-square space-icon"></span>Read the Details</a>
                        <a v-else-if="showEventComments !== event.createdOn" href='#' @click.prevent="viewEventComments(character,event)" style="margin:10px 0;" class='banter' button><span class="oi oi-comment-square space-icon"></span>Continue The Tale</a>
                        <a v-else-if="event.attempt && showEventComments == event.createdOn"  @click="closeEventComments()" class="banter"><span class="oi oi-comment-square space-icon"></span> Close Scene Details</a>
                      </div>

                    </div>
                  </div>
                  <!-- TODO REMOVE LEGACY control -->
                  <!-- v-show="eventComments.length" -->
                  <div v-if="showEventComments == event.createdOn"  class="comments">
                    <!-- <a  @click="closeEventComments()" class="close close-comments">X</a> -->
                    <div  v-for="(comment,index) in eventComments" v-bind:key='comment' class="comment" >
                      <!-- If other player -->
                    <div v-if="comment.userId !=character.userId" class="received_msg"><div class="received_withd_msg" @click.prevent="toggleCommentControl(comment.id)">
                        <p style="text-align: left;"><b>{{ comment.userName }} <i class="ra ra-helmet" ></i></b><br> 
                        {{ comment.content }}
                        <span v-if="comment.comment_tilt_attempt" class="time_date">{{result_text(comment.comment_tilt_attempt)}} <i :class="'ra '+result_icon(comment.comment_tilt_attempt)"></i></span> 
                      </p>
                      </div>
                    </div>
                    <!-- if creator -->
                    <div  v-if="character.userId == comment.userId" class="outgoing_msg">
                      <div  class="sent_msg" @click.prevent="toggleCommentControl(comment.id)" style="position: relative;">
                        <p style="text-align: left;">
                          <!-- <i class="ra ra-helmet" ></i> -->
                          <b style="font-size:10px;">{{comment.userName}} </b><br> {{comment.content}}
                          <span v-if="comment.comment_tilt_attempt" class="time_date white">{{result_text(comment.comment_tilt_attempt)}} <i :class="'ra '+result_icon(comment.comment_tilt_attempt)"></i></span> 

                        </p>
                        <div v-if="comment.tags" style="    position: absolute; top: 0; right:0px;" ><span v-for="(tag,index) in comment.tags" v-bind:key='tag' class="tag" @click.prevent="tagCommentRemove(index,comment)"><i :class="'ra '+tagLogo(tag)" ></i></span></div>
                        </div>
                      <!-- Controls -->
                      <div v-if="showCommentControl == comment.id"  class="" style="background: rgba(1,1,1,.1); z-index:1; width: 100%; height: 100%; clear: both;">
                      <div >
                        <!-- <span style="font-size:8px; color:#111; text-align: center;" >Scene Controls</span> -->
                        <a href='#' v-if="index == eventComments.length -1 && !event.attempt" @click.prevent="removeComment(comment.id)" class="btn btn-danger btn-sm" button>Remove</a>
                        <a v-if="index == eventComments.length -1 && !event.attempt" class="btn btn-primary btn-sm" ref="el" type="button" v-on:click="addComment(event,'dm_retry')"><i class='ra ra-dragon'></i> Dungeon Buddy Retry</a>
                        <div>
                        <div v-if="extractChainsOfProperNouns(comment.content).length > 1" class="proper-text clearfix" style="padding:0 20px;">
                          <span style="font-size:12px;">Generate Select Name</span>
                          <div v-for="(tag) in extractChainsOfProperNouns(comment.content)" v-bind:key='tag'><div v-if="tag.length > 4" class="tag btn btn-sm " :class="tag==generateCommentName ? 'btn-primary':'btn-outline-primary'" v-on:click="setGenerateFromCommentName(tag)">{{tag}} </div></div>
                        </div>
                        <div class="clearfix">
                        <hr>
                        <span style="font-size:12px;">Generate Select Action</span><br>
                        <a  class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="generateFromComment('item',comment)"><i class='ra ra-torch'></i> Item</a>
                        <a  class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="generateFromComment('npc',comment)"><i class='ra ra-helmet'></i> NPC</a>
                        <a  class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="generateFromComment('creature',comment)"><i class='ra ra-dragon'></i> Creature</a>
                        <a  class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="generateFromComment('faction',comment)"><i class='ra ra ra-crown'></i> Faction</a>
                        <a  class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="generateFromComment('skill',comment)"><i class='ra ra ra-trophy'></i> Skill</a>
                        <a  class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="generateFromComment('rumor',comment)"><i class="ra  ra-x-mark" ></i> Rumor</a>
                        <!-- generateFromComment -->
                        <a href='#' @click.prevent="tagComment('heart',comment)" class="btn btn-primary btn-sm" button><i class="ra ra-two-hearts"></i> Heart</a>
                        <a href='#' @click.prevent="toggleCommentControl()" class="btn btn-secondary btn-sm" button> X close</a>
                        </div>
                        </div>
                        <!-- <a href='#' @click.prevent="" class="btn btn-secondary btn-sm" button><i class='ra  ra-quill-ink'></i> End Scene {{ event.comments }}</a> -->
                      </div>
                    </div>
                      </div>
                    </div>
                    <!-- Loading -->
                    <div v-if='showDungeonBDLoad' class="spinner-border text-success" role="status">
                      <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
                    </div>
                    <div v-if='showCommentLoading' class="spinner-border text-success" role="status">
                      <span class="sr-only"><i class="ra  ra-player true"></i></span>
                    </div>
                    <div v-if="!event.attempt"  class="c-modal justify-content-md-center" style="padding:0 20px;">
                      <div  class="">
                        <hr> 
                        <!-- <span style="font-size:8px; color:#111; text-align: center;" >Scene Controls</span> -->
                        <a href='#' @click.prevent="toggleCommentModal(event)" class="btn btn-primary btn-sm" button><span class="oi oi-comment-square space-icon" style="color:white;"></span> Add to Scene</a>
                        <a v-if="!$route.query.gm" href='#' @click.prevent="toggleActionModal()" class="btn btn-secondary btn-sm" button><i class='ra  ra-quill-ink'></i> End Scene </a>
                        <a v-else-if="$route.query.gm" href='?player=true'  class="btn btn-secondary btn-sm" button><i class='ra  ra-quill-ink'></i> Switch to Player </a>
                        <br>
                        <span v-if="$route.query.gm">GM's cant end scene only players.</span>
                      <hr>
                    </div>
                      
                    </div>
                  </div>
                </div>
              </div>
            </transition>
            <transition name="fade">
              <div v-if="showCommentModal && !event.attempt" class="c-modal">
                <div class="row" style="padding:0 20px;">
                <span class='col' style="float:left;">Add to Scene</span>
                <div  class='col'><span v-if="character.doom">Doom <i class="ra ra-focused-lightning"></i> {{character.doom}}</span>
                <span v-else >Doom <i class="ra ra-focused-lightning"></i> 0</span></div>
                <div  class='col'><span v-if="character.lore">Lore <i class="ra ra-sun-symbol"></i> {{character.lore}}</span>
                <span v-else >Lore <i class="ra ra-sun-symbol"></i> 0</span></div>
                </div>
                <div class="c-container received_msg" style="margin-top:10px;">
                  <form @submit.prevent>
                    <textarea v-model.trim="comment.content" class='form-control banter' style="min-height: 80px;" v-on:keyup="checkBanter"></textarea>
                    <button @click.prevent="addComment(event,'player')" :disabled="comment.content == ''" class="btn btn-primary btn-sm" button><span class="oi oi-comment-square space-icon" style="color:white;"></span> Add to Scene</button>
                    <button @click.prevent="addComment(event,'player_tilt')" :disabled="remainingTilt(event)==0 || comment.content == ''" class="btn btn-primary btn-sm" button><span class="ra ra-perspective-dice-random" style="color:white;"></span> Tilt {{remainingTilt(event)}}</button>
                  </form>
                  <div class="banter-tags" style="clear:both;"><div class="banter-tags" v-html="banterTags"></div> <div></div></div>
                </div>
                <div style="padding: 4px 20px 20px 20px" class="clearfix">
                  <div v-if="character.npcs" style="width:100%;" class="clearfix"><div v-for="(npc) in character.npcs" v-bind:key='npc' class="tag"><div v-if="npc.relationship && npc.relationship=='companion'">
                    <div class="small-image float-start"><img v-if="npc.src" class=""  :src="npc.src" :style="characterImage(npc.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png"></div>
                    <div style="float:left;">
                      <div class="more" style="text-align:left;" v-on:click.prevent="toggleSubViewObject('npc',npc)">{{npc.name}}</div> 
                      <div style="font-size:10px; text-align:left;">"I don't like this... {{event.rumor}}</div> </div> </div> </div></div>
                  <hr>
                      Active NPCS & Creatures in Scene
                      <div v-if="character.npcs" ><div v-for="(npc) in character.npcs" v-bind:key='npc' class="tag"><div v-if="npc.active">
                        <div class="small-image float-start"><img v-if="npc.src" class=""  :src="npc.src" :style="characterImage(npc.src)">
                  <img v-else  class="small-image float-left" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/in-the-style-of-a-black-and-white-pencil-drawing-a-partially-completed-sketch-of-an-archer-with-a-t.png"></div>
                        <span class="more" v-on:click.prevent="toggleSubViewObject('npc',npc)">{{npc.name}}</span> </div></div></div>
                      <div v-if="character.creatures" ><div v-for="(creature) in character.creatures" v-bind:key='creature' class="tag"><div v-if="creature.active"><img class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Creature.jpg">{{creature.name}} </div></div></div>
                      </div>
              </div>
            </transition>
          </div>



          <div  v-if="showDungeonBuddyModal & !event.attempt" class='take-action' style='margin-top:10px;'>
            <form  @submit.prevent class='clearfix'>
             <div><h6>Dungeon Buddy Controls and Settings</h6>
              <a href='#' @click.prevent="toggleDungeonBuddyModal()" class="btn btn-outline-secondary btn-sm" button> <i :class='"ra  ra-helmet "+dungeonBuddyChat'></i> Close Dungeon Buddy Settings </a>
             <hr/>
              <span class="time_date"> Enable or Disable Dungeon Buddy Chat Response on Click.</span>
              <a class="btn btn-outline-primary btn-sm" v-on:click="toggleDungeonBuddyChat()" ref="el" type="button"><i :class='"ra  ra-aura "+dungeonBuddyChat' ></i> Dungeon Buddy Chat : {{dungeonBuddyChat }}</a>
            </div>
            <div style='margin-top:40px'>
              <h6>Dungeon Buddy Help</h6>
              <hr/>
              <span class="time_date"> Dungeon Buddy Help: Type a short note on the help you want and click on the button that relates to get a response.</span>
            </div>
            <div style='margin-top:8px'>
             <textarea style='margin-top:8px' class="form-control" v-model.trim="ActionForm.attempt" aria-label="With textarea"
             :placeholder="'Dungeon Buddy, help me ...'">...</textarea>
           </div>
           <div style='margin-top:8px'>
             <a class="btn btn-outline-primary btn-sm" v-on:click="toggleDungeonBuddyChat()" ref="el" type="button"><i :class='"ra  ra-aura "+dungeonBuddyChat' ></i> Dungeon Buddy Chat : {{dungeonBuddyChat }}</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button"  v-on:click="DungeonBuddyRecommendation('Name')"> <i class='ra ra-aware'></i> Name</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button"  v-on:click="DungeonBuddyRecommendation('NPC')"> <i class='ra ra-knight-helmet'></i> NPC</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="DungeonBuddyRecommendation('Item')"><i class='ra ra-heart-bottle'></i>Item</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="DungeonBuddyRecommendation('Location')"><i class='ra ra-compass'></i> Location</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="DungeonBuddyRecommendation('Faction')"><i class='ra ra-muscle-up'></i> Faction</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="DungeonBuddyBuild('encounters')"><i class='ra ra-dragon'></i> Encounter</a>
             <!-- TODO REDOO -->
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="addComment(event,'dm_retry')"><i class='ra ra-dragon'></i> Dungeon Buddy Retry</a>
             <a class="btn btn-outline-primary btn-sm" ref="el" type="button" v-on:click="DungeonBuddyRecommendation('')">Other</a>
           </div>
         </form>
         <!-- Old Notes you can remove -->
         <!-- <div ><span class="time_date"> Need an encounter from someone before an action can be taken. <br>In the text box, create one by typing.. <br>ex. #Trap They never saw it coming...</span>
         </div> -->
         <div ><p v-html="dungeonBuddyIdea" style="white-space: pre-wrap;"></p></div>
         <!-- <span class="time_date"> Waiting for attempt of other player.</span> -->
       </div>

       <!-- ATTEMPT -->
       <div v-if="event.attempt" class="outgoing_msg">
        <div class="sent_msg">
          <p >{{event.attempt}}</p>
          <span class="time_date">{{result_text(event.calculated_result)}} <i :class="'ra '+result_icon(event.calculated_result)"></i></span> 
        </div>
      </div>

      <!-- Take action / attempt section -->
      <div v-else-if="!$route.query.gm && showActionModal" class='take-action' style='margin-top:10px;'>
        <h6>End Scene</h6>
        <form v-if="character.encounters && character.encounters.length > 0 && showNextEncounter()!== ''" @submit.prevent class='clearfix'>
         <textarea class="form-control" v-model.trim="ActionForm.attempt" aria-label="With textarea" v-on:keyup="checkBanter"
         :placeholder="character.name+' attempts to...'">...</textarea>
         <div style='margin-top:8px'>
           <a  class="btn btn-primary btn-sm" v-on:click="UpdateAction(event)" ref="el" type="button" :disabled="ActionForm.attempt == ''" button><i class='ra ra-aware'></i> Attempt</a>
         </div>
           <div>
            <hr>
            <div><small>Next Rumor you will investigate in the Next Scene.</small><br><br> <div class="rum"><i class="ra  ra-x-mark"></i> {{showNextEncounter()}}</div></div>
          </div>
       </form>
       <div v-else style="text-align: left;" > 
        <small>Click on your next <b>Rumor</b> to investigate in the next Scene.</small>
        <div class="" v-for="(encounter,index) in character.encounters"  v-bind:key='encounter' style="clear:both;">
              <div class="rumor" v-if="completedEncounter(encounter)!='completed'" :class="completedEncounter(encounter)">
                <span v-if="encounter.rumor" v-on:click.prevent="selectNextEncounter(index)" :class="selectedNextEncounter(encounter)"><i class="ra  ra-x-mark"></i> {{encounter.rumor}}</span>
                <span v-else>Unknown Rumor</span>
              </div> <div v-if="encounter.status">status: {{encounter.status}}</div>
            </div>
       </div>
       <div class="banter-tags" style="clear:both;"><div class="banter-tags" v-html="banterTags"></div> </div>
       <!-- Old can remove -->
       <!-- <div><span class="time_date"> Need an encounter from someone before an action can be taken. <br>In the Text box, create one by typing.. <br>ex. #Trap They never saw it coming...</span></div> -->
       <!-- <span class="time_date"> Waiting for attempt of other player.</span> -->
     </div>
    

    <div v-if="event.result" class="outgoing_msg">
      <div class="sent_msg">
        <p >{{event.result}}</p>
        <span class="time_date"></span> </div>

      </div>
      <!-- Response Action -->
      <div class="take-action" v-else-if="(event.attempt && event.calculated_result < 6 && $route.query.gm) || (event.attempt && event.calculated_result > 5 && !$route.query.gm)">
        <form @submit.prevent class='clearfix'>
         <textarea class="form-control" v-model.trim="ActionForm.result" aria-label="With textarea" placeholder="Resulting in...">...</textarea>
         <a href="#" class="btn btn-primary btn-sm" v-on:click="UpdateAction(event)" ref="el">Result</a>
         <a href='#' @click.prevent="toggleDungeonBuddyModal()" class="btn btn-outline-secondary btn-sm" button> <i :class='"ra  ra-helmet "+dungeonBuddyChat'></i> Dungeon Buddy {{ event.comments }}</a>
       </form>
       <div v-if="nextLocation==''">
        <!-- <div>If you would like to explore a new location, these are the areas you can go! Add one to your text with a @LocationName to go there. Of if you know the rumor you want to pursue type</div> -->
        <div>Next Rumor you will investigate.<br> <div class="rum"><i class="ra  ra-x-mark"></i> {{showNextEncounter()}}</div></div>
        <div class="banter-tags" v-for="location in character.locations" v-bind:key='location'>

          <div class="banter-tag" v-if="location.encounters && location.encounters.length > 0" > <i class="ra  ra-pyramids"></i> {{location.name}}</div>
        </div>

      </div>
      <div v-else>Next Location!
        <div class="banter-tags">
          <div class="banter-tag"><i class="ra  ra-pyramids"></i> {{nextLocation}}</div>
        </div>
      </div>
      <span class="time_date" style="clear:both;"> Waiting for result. </span> 
    </div>

      <div class="take-action" v-else-if="(event.attempt)"><a v-if="!showDungeonBDLoad" href='#' @click.prevent="DungeonBuddyContinue(event)" class="btn btn-outline-secondary btn-sm" button> <i :class='"ra  ra-helmet "+dungeonBuddyChat'></i> Dungeon Buddy Continue</a><div v-else class="spinner-border text-success" role="status">
                      <span class="sr-only"><i class="ra  ra-helmet true"></i></span>
                  </div>
      </div>
  </div>

</div>
</div>
</div>
</div>
</div>
</template>

<script>
 // import { mapState } from 'vuex'
 const fb = require('../../firebaseConfig.js')
 // const encounters = require('../../helpers/encounters.js')
 import {generate_encounters,encounter_response} from  '../../helpers/encounters.js'
 import {rumors, locations, npcs, creatures, factions, items, generate_encounter_result, fetchIdeasObject, skills} from '../../helpers/generator.js'
 import { toRaw } from 'vue'
 import NPC from "./NPC.vue";
 import SingleLocation from "./SingleLocation.vue";
 import SingleFaction from "./SingleFaction.vue";
 import SingleItem from "./SingleItem.vue";
 import SingleSkill from "./SingleSkill.vue";
 import SingleInstructions from "./SingleInstructions.vue";
 import SingleCreature from "./SingleCreature.vue";
 import MainMap from "./MainMap.vue";
 // import formbricks from "../../helpers/formbricks.js";
 import formbricks from "@formbricks/js";
 // import dropdown from 'bootstrap'
 export default {
  view : "history", 
  // props: {
  //   view: {
  //   type: String,
  //   default: "choice"
  //   }
  // },
   data() {
    return {
      // view: 'choice',
      ActionForm: {
        email: '',
        password: ''
      },
      nextLocation:'',
      character: {
      },
      location: {
      },
      currentEvent:{
      },
      comment: {
        eventId: '',
        userId: '',
        content: '',
        eventComments: 0
      },
      showCommentControl:false,
      showDungeonBDLoad:false,
      showCommentLoading:false,
      showCommentModal: false,
      showActionModal: false,
      showBanterInfo:true,
      showEventComments:false,
      showDungeonBuddyModal: false,
      dungeonBuddyChat: true,
      dungeonBuddyIdea: 'No Ideas, enter some content and hit the request type.',
      dungeonBuddyScene: 'No Scene....',
      view: "current",
      banterTags:'',
      eventComments:[],
      events: [],
      waitForScene: false,
      viewNPC: {},
      viewSkill: {},
      viewLocation: {},
      viewFaction: {},
      viewCreature: {},
      viewItem: {},
      generateCommentName: '',
      showMarquee: false,
      deleteCharacterCheck: false,
    }
  },
  components: {
    NPC,
    SingleLocation,
    SingleFaction,
    MainMap,
    SingleInstructions,
    SingleCreature,
    SingleItem,
    SingleSkill
  },
  computed: {

  },
  mounted() {
    this.getCharacter();
    if(this.$store.state.currentUser && this.$store.state.userProfile){
      // console.log(this.$store.state.currentUser.uid)
      // console.log(this.$store.state.userProfile)
      if(!('formbrick' in this.$store.state.userProfile)){
        console.log('initialize formbrick')
       formbricks.init({
            environmentId: "clt8z2eko27y35zwkchhbv1q7",
            apiHost: "https://app.formbricks.com",
            userId: this.$store.state.currentUser.uid
          });
       this.$store.state.userProfile['formbrick'] = true;
      }
    }
  },
  methods: {
    rumors,
    locations,
    npcs,
    creatures,
    factions,
    items,
    encounter_response,
    generate_encounters,
    generate_encounter_result,
    fetchIdeasObject,
    skills,
    async setCharacter(event){
      var rect = event.target.getBoundingClientRect();
      var x = event.clientX - rect.left;
      var y = event.clientY - rect.top;
      var width = rect.width;
      var height = rect.height;
      console.log("Clicked at: " + x + ", " + y);
      var src = 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Characters.jpg?x='+x+'&y='+y+'&width='+width+'&height='+height;
      console.log(src)
      // this.character.src = src;
      let characterId = this.character.id;
      await fb.charactersCollection.doc(characterId).update({
              src: src,
            }).then(ref => {
              console.log(ref)
            }).catch(err => {
              console.log(err)
            }); 
    },
    toggleMarquee(){
      this.showMarquee = !this.showMarquee;
    },
    characterImage(src){
      var response = '';
      var calc = {'y':0,'x':0,'height':0,'width':0}
      var pop = src.split('?')
      pop = pop[1]
      pop = pop.split('&')
      pop.forEach(function(pair) {
        var key = pair.split('=')[0];
        var value = pair.split('=')[1];
        calc[key] = value;
      });
      response = 'position:absolute; left:-'+calc['x']+'px; top:-'+calc['y']+'px; height:'+Math.round(calc['height'])+'px; width:'+Math.round(calc['width'])+'px;';
      // console.log(response)
      return response
    },
    async DungeonBuddyContinue(info){
      console.log('clicked')
      console.log('last')
      // Impacts
      this.showDungeonBDLoad = true;
      var impacted = this.addDoom()
      console.log(impacted)
      console.log(this.currentEvent.attempt)
      console.log(this.currentEvent.calculated_result)
      console.log(info)
      var more_context_events = toRaw(this.eventComments)
      var more_context = '';
      var attempt = this.currentEvent.attempt
      var result_text = this.result_text(this.currentEvent.calculated_result)
      try{
        more_context = more_context_events[this.eventComments.length-1].content+' '+more_context
      }catch{
        console.log('error not long enough')
      }
      try{
        more_context = more_context_events[this.eventComments.length-2].content+' '+more_context
      }catch{
        console.log('error not long enough')
      }
      var current_rumor = this.currentEvent.rumor || '';
        var current_twist = this.currentEvent.twist || '';
        var current_type = this.currentEvent.type || '';
        var settings = {
          'current_twist':current_twist,
          'current_rumor':current_rumor,  
          'character_name': this.character['name'] || '',
          'attempt': attempt,
          'more_context': more_context,
          'type': current_type,
          'result_text': result_text,
          'impacted': impacted,
        }
      //Generate Result
      //ActionForm.result
      var result = await this.generate_encounter_result(settings)
      console.log(result)
      this.ActionForm.result = result['result'];
      // Just Pass info
      this.showDungeonBDLoad = false;
      this.UpdateAction(info)
    },
    extractChainsOfProperNouns(text) {
    // Match chains of proper nouns (sequences of capitalized words)
    var properNounsRegex = /\b[A-Z][a-z]*(?:\s+[A-Z][a-z]*)*\b/g;
    //Adding of works.
    // var properNounsRegex = /\b(?:[A-Z][a-z]*\s*)+(?=\b)(?:(?!of)\b\s*(?:[A-Z][a-z]*\s*)+)?/g;
    var properNouns = text.match(properNounsRegex);
    properNouns = text.match(/(?:[A-Z][a-z]*'?[A-Z]?[a-z]*\s?)+/g);

    // var properNounsRegex = /\b(?:[A-Z][a-z]*\s*)+(?=\b)((?:\sof\s+)(?:[A-Z][a-z]*\s*)+)?/g;
    
    var properNounsRegex2 = /\b[A-Z][a-z]*(?:\s+[A-Z][a-z]*)*\s+of\s+[A-Z][a-z]*(?:\s+[A-Z][a-z]*)*\b/;
    properNouns.push.apply(properNouns,text.match(properNounsRegex2));
    
    if (properNouns) {
      properNouns = Array.from(new Set(properNouns))
        return properNouns;
    } else {
        return [];
    }
    },
    remainingTilt(event){
      if('tilt' in event && typeof(event.tilt)!=='undefined'){
        return 3 - event.tilt
      }
      return 3
    },
    tagLogo(type){
      var logos = {
        'item':'ra-torch',
        'npc':'ra-helmet',
        'heart':'ra-two-hearts',
        'rumor':'ra-x-mark',
        'faction':'ra-crown',
        'creature':'ra-creature',
      }
      return logos[type]
    },
    addDoom(){
      console.log('add Doom')
      // console.log(this.character[activeType])
      const impact_types = ['npcs','items','factions','character']
      var impact_type = impact_types[Math.floor(Math.random()*impact_types.length)];
      console.log(impact_type)
      if(impact_type =='character'){
        this.character['doom'] == this.character['doom']+1 || 1;
        return {
        'name': this.character.name,
        'type': 'character'
        }
      }
      
      var array = toRaw(this.character[impact_type])
      var doom_impacted = Math.floor(Math.random()*array.length);
      this.character[impact_type][doom_impacted]['doom'] = this.character[impact_type][doom_impacted]['doom']+1 || 1;
      console.log(this.character[impact_type][doom_impacted]);
      return {
        'name': this.character[impact_type][doom_impacted].name,
        'type': impact_type
      }
    },
    clearActive(activeType){
      // console.log(activeType)
      // console.log(this.character[activeType])
      var array = toRaw(this.character[activeType])
      var update = false;
      if(activeType in this.character && this.character[activeType].length > 0){
      for (let i = array.length - 1; i >= 0; i--) {
           if("active" in array[i]){
              delete array[i].active
              update = true
            }
        }
      }
    // this.character[activeType] = array
      // console.log(array)
      if(update){
        this.character[activeType] = array
        // console.log('updated');
        // console.log(toRaw(this.character[activeType]))
      }
      
    },
    doomCounter(){
      var doom = this.eventComments.length;
      return doom
    },
    loreCounter(){
      var lore = 0;
      return lore
    },
    eventImage(type){
      var clean_type = new String(type).toLowerCase();
      if(clean_type=='start'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
      }
      if(clean_type=='exploration'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Dungeon.jpg'
      }
      if(clean_type=='creature'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Creature.jpg'
      }
      if(clean_type=='boss' || clean_type=='nemisis'){
        return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Generate%20an%20image%20of.jpg'
      }
      return 'https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Start.jpg'
    },
    relationPrint(status){
      var response = ''
      if(status == 1){
        return 'favorable'
      }
      if(Math.abs(status) > 4){
        response = 'very '
      }
      if(status < 0){
        return response+'hated'
      }
      else if (status > 0){
        return response+'loyal'
      }
      return 'neutral'
    },
    statusPrint(status){
      // console.log(status)
      var response = ''
      if(Math.abs(status) > 4){
        response = 'very '
      }
      if(status < 0){
        return response+'wraithful'
      }
      else if (status > 0){
        return response+'favorable'
      }
      return 'neutral'
    },
    showNextEncounter(){
      var response = ''
      this.character.encounters.forEach(function (encounter){
        // console.log('check')
        // console.log(encounter)
        if('next' in encounter && encounter['next']){
          // console.log('true')
          response = encounter['rumor']
        }
      });
      return response;
    },
    selectedNextEncounter(encounter){
      // console.log(next)
      if('next' in encounter && encounter['next']){
        // console.log('computed')
        return 'selected'
      }
    },
      completedEncounter(encounter){
      if(typeof(encounter) === 'object' && 'chosen' in encounter && encounter['chosen']){
        // console.log('return completed!')
        // console.log('computed')
        return 'completed'
      }
    },
    selectNextEncounter(rumorIndex){
      // console.log(toRaw(this.character.encounters));
      // console.log(rumorIndex)
      //Todo for each
      this.character.encounters.forEach(function (encounter) {
        encounter['next'] = false;
      });
      if(!("chosen" in this.character.encounters[rumorIndex])){
        this.character.encounters[rumorIndex]['next'] = true;
      }
      
      // this.character.encounters[rumorIndex]['next'] = ! this.character.encounters[rumorIndex]['next']
    },
    DungeonBuddyRecommendation(type){
      var preamble='You are a Game Master in a roleplaying game, a Character has asked for help coming up with ideas for a ';
      var postamble = ' Here is some additional context provided by the player.';
      // Todo wire up context
      var context = '';
      var ask = preamble+type+postamble+context;
      const banter = this.fetchIdeas(ask)
      console.log(banter)
    },
    async DungeonBuddyBuild(type,context){
      // TODO LEGACY
      console.log('LEGACY')
      var response = '';
      var active_npc = ''
      if('npcs' in this.character && this.character.npcs.length > 0){
          // console.log('NPCS!')
          this.character.npcs.forEach(function (npc) {
              //Companion will always be active
              // Set active some where. || ('relationship' in npc && npc.relationship !== 'companion')
              if("active" in npc){
                // console.log('found!')
                active_npc = JSON.stringify(npc);
                // console.log('im active!')
              }
            });
        }
      if(type=='encounters'){
        response = await rumors(
          {
            context:context,
            epic_motivation: this.character.epic_motivation,
            nemisis: this.character.npcs[1],
            active_npc:active_npc
          })
      }
      if(typeof response === 'object'){
        this.character[type] = this.character[type] || []
        console.log(this.character[type])
        this.character[type].push(response);
        let characterId = this.character.id;
        const update = {}
        update[type] = this.character[type];
        fb.charactersCollection.doc(characterId).update(update).then(ref => {
          console.log(ref)
        }).catch(err => {
          console.log(err)
        });
      }
      return response;
      
    },
    toggleDungeonBuddyChat(){
      this.dungeonBuddyChat = !this.dungeonBuddyChat ;
      // this.comment.eventAction = event.comments;
    },
    toggleCommentControl(id){
      if(id){
        if(this.showCommentControl == id){
          this.showCommentControl = false;
        }else{
          this.showCommentControl = id;
        }
      }else{
        this.showCommentControl = false;
      }
    },
    removeComment(id){
      // fb.commentsCollection.doc(id).delete().then(() => {
      //     console.log("Document successfully deleted!");
      // }).catch((error) => {
      //     console.error("Error removing document: ", error);
      // });

      fb.commentsCollection.doc(id).update({'remove':true}).then(() => {
          // console.log("Document successfully updated!");
      }).catch((error) => {
          console.error("Error removing document: ", error);
      });
    },
    tagComment(tag,comment){
      console.log(toRaw(comment));
      var tags = toRaw(comment).tags || []
      tags.push(tag)
      fb.commentsCollection.doc(comment.id).update({'tags':tags}).then(() => {
          console.log("Document successfully updated!");
      }).catch((error) => {
          console.error("Error removing document: ", error);
      });
    },
    toggleActionModal(){
      this.showActionModal = !this.showActionModal;
      this.showCommentModal = false;
      this.showDungeonBuddyModal = false;
      // this.comment.eventAction = event.comments;
    },
    closeActionModal(){
      this.showActionModal = false;
    },
     toggleDungeonBuddyModal(){
      this.showDungeonBuddyModal = !this.showDungeonBuddyModal;
      this.showCommentModal = false;
      this.showActionModal = false;
      // this.comment.eventAction = event.comments;
    },
    closeDungeonBuddyModal(){
      this.showDungeonBuddyModal = false;
    },
    toggleCommentModal(event) {
      this.comment.eventId = event.id;
      this.comment.userId = event.userId;
      this.comment.eventComments = event.comments;
      this.showBanterInfo = true;
      this.showCommentModal = !this.showCommentModal;
      this.showActionModal = false;
      this.showDungeonBuddyModal = false;
    },
    closeCommentModal() {
      this.comment.eventId = ''
      this.comment.userId = ''
      this.comment.content = ''
      this.showCommentModal = false
    },
    closeBanterInfo() {
      this.showBanterInfo = false;
    },
    checkNextLocation(event){
      // legacy
      var next_location = '';
      var locations = this.character.locations;
      var splice = event.target.value.split(' ');
      splice.forEach(function (item) {
           item = item.replace('@','');
          var defined = item.split(/(?=[A-Z])/);
          var check = defined.join(" ")
          item = check.replace(/([a-z])([A-Z])/g, '$1 $2');
          item = item.replace(/[^a-zA-Z $ @ # & %]/g, "");
          // 

         locations.forEach(function (thing) {
          // console.log('locations');
          // console.log(thing['name'])
          // console.log(item);

          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
          next_location = thing['name'];
          }
        });

         
      });
      this.nextLocation = next_location;

    },
    checkBanter(event){
      
      // check
      var banter_tags ='';
      var text_value = event.target.value;
      var splice = event.target.value.split(' ');

      // Get Characters, Locations, NPCs, and items
      const items = this.character.items || [];
      const npcs = this.character.npcs || [];
      const locations = this.character.locations || [];
      const factions = this.character.factions || [];
      const skills = this.character.skills || [];
      var npc_check = true;
      var item_check = true;
      var faction_check = true;
      var location_check = true;
      
      splice.forEach(function (item) {
        // console.log(item, index);
        // console.log(item);
        var color = ''
        var shape =''
        var defined = item.split(/(?=[A-Z])/);
        var check = defined.join(" ");
        
        // var item_check = true;
        // var faction_check = true;
        // var location_check = true;
        
        item = check.replace(/([a-z])([A-Z])/g, '$1 $2');
        item = item.replace(/[^a-zA-Z $ @ # & %]/g, "");
        if(item.includes('#')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-scroll-unfurled"></i> '+item.replace('#','')+'</div>';
        }
        if(item.includes('@')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-pyramids"></i> '+item.replace('@','')+'</div>';
        }
        if(item.includes('$')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-torch"></i> '+item.replace('$','')+'</div>';
        }
        
        if(item.includes('&&')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-crown"></i> '+item.replace('&&','')+'</div>';
        }else if(item.includes('&')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-helmet"></i> '+item.replace('&','')+'</div>';
        }

        if(item.includes('%')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-perspective-dice-random"></i> '+item.replace('%','')+'</div>';
        }
        


        items.forEach(function (thing) {
          if(item_check && text_value.includes(thing['name'])){
            banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-torch"></i> '+thing['name']+'</div>';
            item_check = false;
          }
          // if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
          //   color = 'blue'
          //   shape='ra-torch'
          // }
        });
        npcs.forEach(function (thing) {
          // console.log(text_value.includes(thing['name']))
          if(npc_check && text_value.includes(thing['name'])){
            banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-helmet"></i> '+thing['name']+'</div>';
            npc_check = false;
          }
          // if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
          //   color = 'blue'
          // shape ='ra-helmet'
          // }
        });

          skills.forEach(function (thing) {
          // console.log(text_value.includes(thing['name']))
          if(npc_check && text_value.includes(thing['name'])){
            banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra ra-trophy"></i> '+thing['name']+'</div>';
            npc_check = false;
          }
          // if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
          //   color = 'blue'
          // shape ='ra-helmet'
          // }
        });

        factions.forEach(function (thing) {
          if(faction_check && text_value.includes(thing['name'])){
            banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-crown"></i> '+thing['name']+'</div>';
            faction_check = false;
          }
          // if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
          //   color = 'red'
          // shape ='ra-crown'
          // }
        });

        locations.forEach(function (thing) {
          if(location_check && text_value.includes(thing['name'])){
            banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-pyramids"></i> '+thing['name']+'</div>';
            location_check = false;
          }
          // if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
          //   color = 'orange'
          // shape = 'ra-pyramids'
          // }
        });

        if(color!=''){
          banter_tags = banter_tags+'<div class="banter-tag '+color+'"><i class="ra '+shape+'"></i> '+item+'</div>';
        }

        
        });
      // console.log(banter_tags);
      // console.log('banter here')
      if(banter_tags==''){
        banter_tags= ''
      }

      this.banterTags = banter_tags;
      
    },
    checkAction(string){
      
      // check
      var banter_tags ='';
      var splice = string.split(' ');

      // Get Characters, Locations, NPCs, and items
      const items = this.character.items || [];
      const npcs = this.character.npcs || [];
      const locations = this.character.locations || [];
      const factions = this.character.factions || [];
      
      
      splice.forEach(function (item) {
        // console.log(item, index);
        // console.log(item);
        var color = ''
        var shape =''
        var defined = item.split(/(?=[A-Z])/);
        var check = defined.join(" ")
        
        item = check.replace(/([a-z])([A-Z])/g, '$1 $2');
        item = item.replace(/[^a-zA-Z $ @ # & %]/g, "");
        if(item.includes('#')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-scroll-unfurled"></i> '+item.replace('#','')+'</div>';
        }
        if(item.includes('@')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-pyramids"></i> '+item.replace('@','')+'</div>';
        }
        if(item.includes('$')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-torch"></i> '+item.replace('$','')+'</div>';
        }
        
        if(item.includes('&&')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-crown"></i> '+item.replace('&&','')+'</div>';
        }else if(item.includes('&')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-helmet"></i> '+item.replace('&','')+'</div>';
        }

        if(item.includes('%')){
          banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-perspective-dice-random"></i> '+item.replace('%','')+'</div>';
        }
        


        items.forEach(function (thing) {
          // console.log('this')
          // console.log(thing['name'].toLowerCase());
          // console.log(item.toLowerCase());
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'blue'
            shape='ra-torch'
          }
        });
        npcs.forEach(function (thing) {
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'red'
          shape ='ra-helmet'
          }
        });
        factions.forEach(function (thing) {
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'red'
          shape ='ra-crown'
          }
        });
        locations.forEach(function (thing) {
          if(thing['name'] && thing['name'].toLowerCase() == item.toLowerCase()){
            color = 'orange'
          shape = 'ra-pyramids'
          }
        });
        if(color!=''){
          banter_tags = banter_tags+'<div class="banter-tag '+color+'"><i class="ra '+shape+'"></i> '+item+'</div>';
        }
        });
      // console.log(banter_tags);
      // console.log('banter here')
      if(banter_tags==''){
        banter_tags= ''
      }
      this.banterTags = banter_tags;
      return banter_tags
      
    },
    setGenerateFromCommentName(tag){
      this.generateCommentName = tag;
    },
    async deleteCharacter(){
      // Delete comments
      if(this.deleteCharacterCheck == false){
        this.deleteCharacterCheck = !this.deleteCharacterCheck;
        return
      }
      let characterId = this.character.id;
      await fb.commentsCollection.where("characterId", "==", characterId)
         .get()
         .then((querySnapshot) => {
            // Iterate through each document in the query results
            querySnapshot.forEach((doc) => {
                // Delete the document
                fb.commentsCollection.doc(doc.id).delete()
                    .then(() => {
                        console.log("Comment successfully deleted!");
                    })
                    .catch((error) => {
                        console.error("Error removing document: ", error);
                    });
            });
         })
         .catch((error) => {
            console.error("Error getting documents: ", error);
         });
      await fb.charactersCollection.doc(characterId).delete()
      .then(() => {
        console.log("Character successfully deleted!");
      })
      .catch((error) => {
        console.error("Error removing document: ", error);
      });
      this.$router.push('/main')
      // DElete chacter
      // await fb.charactersCollection.doc(characterId).delete()
      // .then(() => {
      //   console.log("Document successfully deleted!");
      // })
      // .catch((error) => {
      //   console.error("Error removing document: ", error);
      // });

    },
    async generateFromComment(type,comment){
      //Tag teh comment so we know what generated what.
      this.tagComment(type,comment)
      
      this.showDungeonBDLoad = true
      var more_context = comment.content;
      console.log(type)
      console.log(comment)
      console.log(this.currentEvent.description);
      // more_context = more_context+ 'And this is the scene they were introduced in.'+ this.currentEvent.description;
      var items = this.character.items || [];
      var npcs = this.character.npcs || [];
      var factions = this.character.factions || [];
      var creatures = this.character.creatures || [];
      var encounters = this.character.encounters || [];
      var skills = this.character.skills || [];
      let characterId = this.character.id;
      var lore = this.character.lore || 0;
      var doom = this.character.doom || 0;
      var generate_name = this.generateCommentName || '';
      console.log('set me '+generate_name);
      var active_npc = '';
      // Increment lore up one.
      // lore = lore+1;
      doom = doom+1;
      var player_background = this.character['name']+' '+this.character['description']
      if('npcs' in this.character && this.character.npcs.length > 0){
          // console.log('NPCS!')
          this.character.npcs.forEach(function (npc) {
              if("active" in npc){
                try{
                  active_npc = npc.background;
                }catch{
                  console.log('no background')
                }
              }
            });
        }
      if(type=='npc'){
        var new_npc = await this.npcs({'details':more_context,'player_background':player_background, 'name':generate_name})
        try{
          npcs.push(new_npc)
          console.log('npc');
          console.log(new_npc)
        }catch{
          console.log('failed')
        }
        
      }
       if(type=='skill'){
        var new_skill = await this.skills({'details':more_context,'player_background':player_background, 'name':generate_name})
        try{
          skills.push(new_skill)
          console.log('skill');
          console.log(new_skill)
        }catch{
          console.log('failed')
        }
        
      }
      if(type=='creature'){
        var new_creature= await this.creatures({'details':more_context, 'name':generate_name})
        try{
          // Create and activate.
          new_creature['active'] = true
          creatures.push(new_creature)
          // console.log('creature');
          // console.log(new_creature)
        }catch{
          console.log('failed')
        }
      }
      if(type=='faction'){
        var new_faction = await this.factions({'details':more_context, 'name':generate_name})
        factions.push(new_faction)
      }
      if(type=='rumor'){
        var new_encounter = await this.rumors(
          {'context':more_context, 'epic_motivation': this.character.epic_motivation, 'nemisis': this.character.npcs[1],'active_npc':active_npc})
        encounters.push(new_encounter)
      }
      if(type=='item'){
        var new_item = await this.items({'details':more_context, 'name':generate_name})
        items.push(new_item)
      }
    await fb.charactersCollection.doc(characterId).update({
          npcs:npcs,
          factions:factions,
          creatures:creatures,
          items: items,
          encounters:encounters,
          lore:lore,
          doom:doom,
          skills:skills,
        }).then(ref => {
          console.log(ref)
        }).catch(err => {
          console.log(err)
        });
      this.showDungeonBDLoad = false
    },
    async addComment(info,comment_type) {
      // console.log('addComment')
      this.showCommentLoading = true;
      let eventId = info.createdOn;
      let characterId = this.character.id;
      //why removed
      // let eventComments = this.comment.eventComments
      // Create Events or other things if needed
      var splice = this.comment.content.split(' ');
      var encounters = this.character.encounters || [];
      var items = this.character.items || [];
      var npcs = this.character.npcs || [];
      var factions = this.character.factions || [];
      var creatures = this.character.creatures || [];
      var locations = this.character.locations || [];
      var events = this.character.events || [];
      var skills = this.character.skills || [];
      var additional_info = false;
      var comment_tilt_attempt = false;
      var tilt_result_text = ''

      //DOOM and Lore
      var tilt = false;
      var doom = this.character.doom || 0;
      var lore = this.character.lore || 0;

      var content = this.comment.content;
      if(comment_type=='player_tilt'){
        console.log('tilt')
        comment_type = 'player'
        tilt = true;
        additional_info = true
        // Event should be last event
        var event_index = events.length-1;
        console.log(events[event_index])
        events[event_index]['tilt'] = events[event_index]['tilt']+1 || 1;
        // events[event_index]['tilt']+1
        comment_tilt_attempt = Math.floor(Math.random() * 10)+1;
        tilt_result_text = this.result_text(comment_tilt_attempt)
        
        console.log('tilt! '+tilt)
        // console.log(event_index)
        // console.log(events[event_index]['tilt'])
        // console.log(toRaw(events[event_index]))
        // console.log(comment_tilt_attempt)
        // status bonus
        var bonus = 0;
        if(comment_tilt_attempt > 5){
          console.log('success add lore')
          lore = this.character.lore+1 || 1;
          bonus = 1;
        }else if(comment_tilt_attempt > 0){
          console.log('fail add doom')
          doom = this.character.doom+1 || 1;
          bonus = -1;
        }
      }
      if(comment_type=='dm'){
        //TODO remove this is empty
        content = info.content || ''
      }
      if(comment_type=='dm_retry'){
        //TODO remove this is empty
        // console.log('retry here')
        content = this.eventComments[this.eventComments.length-1].content || ''
        comment_tilt_attempt = this.eventComments[this.eventComments.length-1].comment_tilt_attempt
        // console.log(this.eventComments[this.eventComments.length-1].comment_tilt_attempt)
        if(comment_tilt_attempt){
          tilt_result_text = this.result_text(comment_tilt_attempt)
        }
      }

      var new_encounter = false;
      var new_location = false;
      // Can remove the add.
      splice.forEach(function (item) {
      var clean_text = item.replace(/([a-z])([A-Z])/g, '$1 $2');
      clean_text = clean_text.replace(/[^a-zA-Z $ @ # &]/g, "");
      if(item.includes('#')){
        //old remove
        // banter_tags = banter_tags+'<div class="banter-tag green"><i class="ra  ra-scroll-unfurled"></i> '+item.replace('#','')+'</div>';
        content = content.replace(item,'')
        new_encounter = {'description':content,'type':item.replace('#','')}

        additional_info = true;
      // FixMe Temporary Fix to encounters
        encounters.push(new_encounter);
        console.log('Fix me Temporary status to encounters!')
      }else if(item.includes('@')){
        content = content.replace(item,clean_text).replace('@','');
        new_location = {'name':clean_text.replace('@',''),'lore':1};
        additional_info = true;
      }else if(item.includes('&&')){
        content = content.replace(item,clean_text).replace('&&','');
        factions.push({'name':clean_text.replace('&&',''),'lore':1});
        additional_info = true;
      }else if(item.includes('&')){
        content = content.replace(item,clean_text).replace('&','');
        npcs.push({'name':clean_text.replace('&',''),'lore':1,'appearance':'','personality':'','background':''});
        additional_info = true;
      }else if(item.includes('$')){
        content = content.replace(item,clean_text).replace('$','');
        items.push({'name':clean_text.replace('$',''),'lore':1});
        additional_info = true;
      }
    });
      // apply tilt to a thing
      if(tilt){
        console.log('tilt yes!')
      items.forEach(function (thing) {
         if(content.includes(thing['name'])){
            // console.log('npc here!')
            // thing['lore'] = thing['lore']+1 || 1
            // additional_info = true;
            console.log('item here')
          }
        if(thing['name']){
              content = content.replace(thing['name'].replace(/\s/g, ''), thing['name']);
        }
      });
        npcs.forEach(function (thing) {
          if(content.includes(thing['name'])){
            console.log('npc here!')
            additional_info = true;
            thing['status'] = thing['status'] || 0
            thing['status'] = thing['status']+bonus
            // console.log(thing['status'])
          }
          if(thing['name']){
              content = content.replace(thing['name'].replace(/\s/g, ''), thing['name']);
          }
          // console.log(thing['lore'])
      });
      skills.forEach(function (thing) {
          if(content.includes(thing['name'])){
            console.log('npc here!')
            additional_info = true;
            thing['level'] = thing['level'] || 0
            thing['level'] = thing['level']+bonus
            // console.log(thing['status'])
          }
          if(thing['name']){
              content = content.replace(thing['name'].replace(/\s/g, ''), thing['name']);
          }
          // console.log(thing['lore'])
      });
        factions.forEach(function (thing) {
           if(content.includes(thing['name'])){
             additional_info = true;
            console.log('faction here!')
            thing['status'] = thing['status'] || 0
            thing['status'] = thing['status']+bonus
          }
            if(thing['name']){
              content = content.replace(thing['name'].replace(/\s/g, ''), thing['name']);
        }
      });
      }
      //If new encounter link to location, new or old
      if(new_encounter && new_location){
        console.log('new encounter and new locations');
        new_location['encounters'] =[new_encounter];
        locations.push(new_location)
      }else if(new_encounter){
        console.log('match new_encounter to old location');
        var lower = content.toLowerCase();

         locations.forEach(function (thing,index) {
           if(content.includes(thing['name'])){
              console.log('npc here!')
              // thing['lore'] = thing['lore']+1 || 1
            }
          if(thing['name']){
            content = content.replace(thing['name'].replace(/\s/g, ''), thing['name']);
            console.log('should match');
            console.log(lower);
            console.log(thing['name'].replace(/\s/g, '').toLowerCase());
          }
          if(thing['name'] && lower.indexOf(thing['name'].replace(/\s/g, '').toLowerCase()) > -1){
            console.log('Updating');
            if(typeof(locations[index]['encounters']) ==='undefined'){
              locations[index]['encounters'] = [];
            }
            new_encounter['content'] = content;
            locations[index]['encounters'].push(new_encounter)
          }
        });
      }else if(new_location){
         console.log('not new encounter, just a location');
        locations.push(new_location)
      }
      // Dungeon Buddy Chat Call
      // console.log('comment type '+comment_type)
      if(this.dungeonBuddyChat && comment_type == 'player' || comment_type=='dm_retry'){
        // check if encounter
        var prompt = ''
        // TODO Fix or CHeck FIx
        var current_location = 'City of Mecia';
        if('location' in this.currentEvent){
          current_location = this.currentEvent.location.name || ''
        }
        // Build Context
        var more_context_events = toRaw(this.eventComments)
        var more_context = '';
        try{
          more_context = more_context_events[this.eventComments.length-1].content+' '+more_context
        }catch{
          console.log('error not long enough')
        }
        try{
          more_context = more_context_events[this.eventComments.length-2].content+' '+more_context
        }catch{
          console.log('error not long enough')
        }

        var current_rumor = this.currentEvent.rumor || '';
        var current_twist = this.currentEvent.twist || '';
        var current_type = this.currentEvent.type || '';
        var introduce_npc = false;
        var npc_introduction = ''
        var active_npc = ''
        var scene_length = this.eventComments.length;
        var scene_settings = {
          'current_twist':current_twist,
          'current_rumor':current_rumor, 
          'scene_length':scene_length, 
          'active_creature':active_creature, 
          'intro_creature':introduce_creature,
          'active_npc': active_npc,
          'npc_introduction': npc_introduction,
          'next_rumor': '',
          'character_name': this.character['name'] || '',
          'companion_info': '',
          'content': content,
          'more_context': more_context,
          'type': current_type,
          'doom': this.character.doom || 0,
          'lore': this.character.lore || 0,
          'tilt': tilt_result_text || '',
          'current_location': current_location || ''
        }
        // console.log(scene_settings)
        // Active NPC none Companion
         if('npcs' in this.character && this.character.npcs.length > 0){
          // console.log('NPCS!')
          this.character.npcs.forEach(function (npc) {
              //Companion will always be active
              // Set active some where. || ('relationship' in npc && npc.relationship !== 'companion')
              if("active" in npc){
                // console.log('found!')
                scene_settings.active_npc = JSON.stringify(npc);
                // console.log('im active!')
              }
              if("relationship" in npc && npc["relationship"]=="companion"){
                scene_settings.companion = JSON.stringify(npc);
              }
            });
        }
        // Introduce NPC
        if(this.currentEvent.type=='start' && introduce_npc){
          console.log('Single Character Intro NPC');
          var new_npc = await this.npcs({'details':more_context})
          additional_info = true;
          npcs.push(new_npc)
          npc_introduction = JSON.stringify(new_npc);
        }
        /// Active Creature or Introduce Creature in creature settings.
        var introduce_creature = true
        var active_creature = ''
        if('creatures' in this.character && this.character.creatures.length > 0){
          this.character.creatures.forEach(function (creature) {
              if("active" in creature){
                scene_settings['active_creature'] = JSON.stringify(creature);
              }
            });
        }
        if(active_creature.length > 0){
          introduce_creature = false
        }
        // Chosen rumor for banter
        if('encounters' in this.character && this.character.encounters.length > 0){
          this.character.encounters.forEach(function (encounter) {
            if(typeof(encounter)!=='undefined' && "chosen" in encounter){
              scene_settings.next_rumor = JSON.stringify(encounter);
            }
        });
        }
        // END SETUP

        if(content.length < 50){
          console.log('General Basic Prompt Short')
          if(prompt.length == 0){
            prompt = 'You are a Game Master in a roleplaying game.'+
            ' The players character name is '+scene_settings.character_name+'. '+
            ' . The player is currently at this location, '+current_location+'.'+
            ' Knowing that respond to the players last comment with a short response text as an NPC or something in the world that helps drive the story forward. '+scene_settings['content']+
            ' Here is more info from the scene. '+scene_settings['more_context']+
            ' Here is the NPC companion info who is traveling with the player.'+scene_settings.companion_info

            if(scene_settings.active_npc!=''){
              prompt = prompt+'The player is currently interacting with an NPC, the NPC has already introduced themselves, here is the NPCS info.'+scene_settings.active_npc
            }else{
              prompt = prompt+' . If you respond as an NPC make sure they have been mentioned prior. '
            }
            prompt = prompt+' . The player is currently at this location '+current_location+'.'
          }
        }else{
          if(prompt.length == 0){
            console.log('General Basic Prompt Long Response')
            // prompt = 'You are a Game Master in a roleplaying game, a player is playing in a scene and just wrote this text. '+content+
            prompt = ' You are a Game Master in a roleplaying game, a player is playing in a scene as the character '+scene_settings.character_name+'.'
            prompt = prompt+' . The player is currently at this location, '+current_location+'.'
            if(scene_settings.active_npc!=''){
              prompt = prompt+' The player is currently interacting with an NPC, the NPC has already introduced themselves, here is the NPCS info. '+scene_settings.active_npc
            }
            prompt = prompt+ ' Knowing that respond to the players last comment with a short response text as an NPC or something in the world that helps drive the story forward. '+' Heres the last comment '+scene_settings['content']+
            ' Here is more info from the scene. '+scene_settings['more_context']
          }
        }

        var prompt_response = await this.encounter_response(scene_settings)
        // if(typeof(prompt_response) !== 'undefined' && 'object_call' in prompt_response){
        //   fetchIdeasObject()
        // }

        if(typeof(prompt_response) !== 'undefined' && 'prompt' in prompt_response && typeof(prompt_response['prompt'])!=='undefined' && prompt_response['prompt']!==''){
          // Uses default prompts above if no match in logic.
          console.log('Encounter Prompt')
          console.log(prompt_response)
          prompt = prompt_response['prompt']
          console.log(prompt)
        }else{
          console.log('used default')
          console.log(prompt)
        }
        this.fetchText(info,prompt)
        // console.log(toRaw(this.currentEvent))
        //End of Dugenon Buddy Chat Call
      }
      if(comment_type=='dm_retry'){
        console.log('skip player call as this is a dm retry')
        return ''
      }
     
      if(additional_info){
        // Updates character with relevent information.
        await fb.charactersCollection.doc(characterId).update({
              encounters: encounters,
              items:items,
              locations:locations,
              npcs:npcs,
              factions:factions,
              creatures:creatures,
              lore: lore,
              doom: doom,
              events:events,
              skills:skills,
            }).then(ref => {
              console.log(ref)
            }).catch(err => {
              console.log(err)
            });
        }

     let userName = 'Not Set'
     if(typeof(this.$store.state.currentUser.displayName) !== 'undefined'){
      userName = this.$store.state.currentUser.displayName ||'Not Set';
     }
     if(userName=='Not Set'){
      userName = fb.auth.currentUser.displayName || '';
     }
     if(comment_type =='dm'){
      userName = 'Dungeon Buddy'
     }
     await fb.commentsCollection.add({
      createdOn: new Date(),
      content: content,
      eventId: eventId,
      userId: this.$store.state.currentUser.uid,
      characterId:characterId,
      userName: userName,
      comment_tilt_attempt: comment_tilt_attempt,
    }).then(() => {
      // console.log(doc)
      this.closeCommentModal()
        }).catch(err => {
          console.log(err)
        })
        // end of Add comment
        this.showCommentLoading = false;
      },
      viewEventComments(character,event) {
        fb.commentsCollection.where('characterId', '==', character.id).where('eventId', '==', event.createdOn).orderBy('createdOn').onSnapshot(querySnapshot => {
           var event_comments = [];

            querySnapshot.forEach(doc => {
              let comment= doc.data();
              comment.id = doc.id;
              if(!('remove' in comment)){
                event_comments.push(comment);
              }
              
              
          });
            // console.log(event_comments);
          this.eventComments = event_comments;
          this.showEventComments = event.createdOn
        });
      },
      closeEventComments(info) {
        console.log('close event comments')
        console.log(info)
        this.eventComments = []
        this.showEventComments = false
      },
      time_convertor: function(time){


        if(typeof(time)==='undefined'){
          return ''
        }
        var day = this.$moment(time.seconds).format('dd');
  // console.log(day);
        if(day == 'Mon'){
          return 'dies Lunae'
        }
        if(day == 'Tue'){
          return 'dies Martis'
        }
        if(day == 'We'){
          return 'dies Mercurii'
        }
        if(day == 'Th'){
          return 'dies Iovis'
        }
        if(day == 'Fri'){
          return 'dies Veneris'
        }
        if(day == 'Sa'){
          return 'dies Saturni'
        }
        if(day == 'Su'){
          return 'dies Solis'
        }
        return day;
      },
      result_text: function(calculated_result){
        if(calculated_result < 6){
          return 'failed'
        }else if (calculated_result > 8){
          return 'success'
        }else if(calculated_result > 5){
          return 'partial success';
        }
        return calculated_result;
      },
      result_icon: function(calculated_result){
        if(calculated_result < 6){
          return 'ra-falling'
        }else if (calculated_result > 8){
          return 'ra-muscle-fat'
        }else if(calculated_result > 5){
          return 'ra-player-shot';
        }
        return calculated_result;
      },
      toggleSubView(select){
        this.view = select
        // this.events = this.character.events
        // console.log(this.view)
        // console.log(select)
        if(select=='current'){
          // console.log(this.character.events[this.character.events.length-1])
          this.events = [this.character.events[this.character.events.length-1]]
        }
        if(select=='history'){
          this.events = this.character.events
        }
        // console.log(this)
      },
      toggleSubViewObject(select,object){
        if(select=='npc'){
          this.viewNPC = toRaw(object)
        }
        if(select=='location'){
          this.viewLocation = toRaw(object)
        }
        if(select=='faction'){
          this.viewFaction = toRaw(object)
        }
        if(select=='creature'){
          this.viewCreature = toRaw(object)
        }
        if(select=='item'){
          // console.log('item')
          // console.log(object)
          this.viewItem = toRaw(object)
        }
        if(select=='skill'){
          this.viewSkill = toRaw(object)
        }
        // console.log(toRaw(object))
        // console.log(toRaw(this.viewNPC))
        this.view = select
        console.log(this.view)
      },
      addGM(){
      //Onclick update to add yourself as a DM
      var follows = []
      if(this.$store.state.userProfile){
        follows = this.$store.state.userProfile.characterFollows || []
      }
      
      if(follows.includes(this.character.id)){
        alert('Already Following!')
        return true
      }
      console.log('updating characterFollows')
      // console.log(this.$store.state.userProfile.characterFollows)
      follows.push(this.character.id)
      // console.log(follows)
      // Probably need a user for user settings
      var userId = this.$store.state.currentUser.uid;
      fb.usersCollection.doc(userId).update({
          characterFollows:follows,
        }).then(ref => {
          console.log('updated')
          console.log(ref)
          
        }).catch(err => {
          console.log(err)
           fb.usersCollection.doc(userId).set({
            characterFollows:follows,
          })
           console.log('created')
        })
      },
      isFollowing(){
        var follows = []
      if(this.$store.state.userProfile){
        follows = this.$store.state.userProfile.characterFollows || []
      }
      
      if(follows.includes(this.character.id)){
        //console.log(this.$store.state.userProfile.characterFollows)
        return true
      }
      return false
      },
      isPlayer(){
        var characterId = this.character.userId;
        // console.log(this.$store.state.currentUser.characterFollows)
        if(characterId!= undefined && characterId == this.$store.state.currentUser.uid){
          // console.log(characterId+'True')
          // console.log(this.$store.state.currentUser.userId)
          return true
        }
        return false
      },
      copyClipboard(){
        var route = location.href.split('?')[0]+'?gm=True'
        // navigator.clipboard.writeText(route);
        navigator.clipboard.writeText(route).then(function() {
          alert("Character Link Copied to Clip Board: " + route);
        });
      },
      feedbackOpen(){
        console.log('feedback')
        if (typeof window !== "undefined") {
          console.log('Main Feedback')
          formbricks.track("Main Feedback");
        }
      },
      getCharacter(){
        // console.log('get characters')
        // fb.charactersCollection.where('characterId', '==', character.id).where('eventId', '==', event.createdOn).onSnapshot(querySnapshot => {
        //          var event_comments = [];

        //           querySnapshot.forEach(doc => {
        //             let comment= doc.data();
        //             comment.id = doc.id;
        //             event_comments.push(comment);
                    
        //         });
        
        //         this.eventComments = event_comments;
        //         this.showEventComments = event.createdOn
        //       });
        fb.charactersCollection.doc(this.$route.params.id).onSnapshot(snapshot=>{
        if (snapshot.exists) {
          // console.log('snapshot update')
          let character = snapshot.data();
          character.id = snapshot.id;
          this.character = character
         

          this.getLocation(character.location_id);
          if(typeof(character.events)==='undefined'){
            console.log('undefined')
            var gameTime = this.$moment(0).toDate();
            var encounter_settings = {'gameTime':gameTime}


            var first_event = this.generate_encounters(encounter_settings);
            var events = [];
            //Maybe remove?
            events.push(first_event);
            this.character.events = events;
            this.events = events;
          }
          // If events reload
          if(this.events.length == 0){
            console.log('load events')
            var last_event = character.events.length -1;
            this.currentEvent = character.events[last_event];
            this.events = [character.events[last_event]]
          }
        } else {
                // snapshot.data() will be undefined in this case
                console.log("No such document!");
              }  
            })
    },
    whatCharacter(){
      console.log(this.character);
    },
    getLocation(location_id){
      
      if(typeof(location_id)==='undefined'){
        this.location = {'name':'not set!'}
        return ''
      }
      let ref = fb.locationsCollection.doc(location_id)
      ref.get()
      .then(snapshot => {  //DocSnapshot
        if (snapshot.exists) {
          let location = snapshot.data();
          this.location = location
        } else {
                // snapshot.data() will be undefined in this case
                console.log("No such document!");
              }  
            })
    },
    fetchText(info,prompt){
      // console.log(prompt);
      this.showDungeonBDLoad = true
        const prompt_data = {contents: [{parts:[{text:prompt}]}]};
        // console.log(JSON.stringify(prompt_data));
        const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
        fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          // mode: "cors", // no-cors, *cors, same-origin
          // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: "follow", // manual, *follow, error
          // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
        }).then(response => response.json())
          .then(data => {
            // console.log('Response:', data);
            // Handle the API response here
            // console.log(data.candidates[0].content.parts[0].text)
            console.log(data)
            const content = data.candidates[0].content.parts[0].text
            info.content = content
            this.addComment(info,'dm')
            this.showDungeonBDLoad = false
          })
          .catch(error => {
            console.error('Error:', error);
            this.showDungeonBDLoad = false
            // Handle errors here
          });
        // return response.json(); // parses JSON response into native JavaScript objects
    },fetchIdeas(prompt){
      console.log(prompt);
      console.log('here prompt')
        console.log(process.env.VUE_APP_GOOGLE_AI_API_KEY);
        const prompt_data = {contents: [{parts:[{text:prompt}]}]};
        console.log(JSON.stringify(prompt_data));
        const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
        fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          // mode: "cors", // no-cors, *cors, same-origin
          // cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
          // credentials: "same-origin", // include, *same-origin, omit
          headers: {
            "Content-Type": "application/json",
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          // redirect: "follow", // manual, *follow, error
          // referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
        }).then(response => response.json())
          .then(data => {
            console.log('Response:', data);
            // Handle the API response here
            console.log(data.candidates[0].content.parts[0].text)
            var content = data.candidates[0].content.parts[0].text
            // Legacy splitter save
            // const digit_check = /\d+/;
            // const content_array = content.split(digit_check)
            // const content_astrick = content.split('*')

            // console.log('length '+content_array.length);
            // if(content_array.length > 1){
            //   console.log('long')
            //   content = content_array.join("/n")
            // }else if(content_astrick.length > 1){
            //   console.log('not long')
            //   content = content_astrick.join("/n")
            // }
            this.dungeonBuddyIdea = content;
          })
          .catch(error => {
            console.error('Error:', error);
            // Handle errors here
          });
        // return response.json(); // parses JSON response into native JavaScript objects
    },
    async setTheScene(prompt){
      // console.log('Scene prompt')
      //  console.log(prompt);
        console.log(process.env.VUE_APP_GOOGLE_AI_API_KEY);
        const prompt_data = {contents: [{parts:[{text:prompt}]}]};
        console.log(JSON.stringify(prompt_data));
        const url = 'https://generativelanguage.googleapis.com/v1beta/models/gemini-pro:generateContent?key='+process.env.VUE_APP_GOOGLE_AI_API_KEY
        const call = await fetch(url, {
          method: "POST", // *GET, POST, PUT, DELETE, etc.
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(prompt_data), // body data type must match "Content-Type" header
        }).then(response => response.json())
          .then(data => {
            console.log('Response:', data);
            // Handle the API response here
            console.log(data.candidates[0].content.parts[0].text)
            var content = data.candidates[0].content.parts[0].text
            this.dungeonBuddyScene = content;
            this.waitForScene = false
          })
          .catch(error => {
            console.error('Error:', error);
            this.waitForScene = false
            // Handle errors here
          });
          return call;
        // return response.json(); // parses JSON response into native JavaScript objects
    },
    async UpdateAction(info) {
      console.log('Ran UpdateAction - with event')
      // console.log(info);
      var event_time = info.createdOn;
      var attempt = this.ActionForm.attempt || '';
      console.log(attempt)
      var result = this.ActionForm.result || '';
      var results = this.checkAction(attempt);
      console.log(results)
      // never used
      // var character = this.character;
      //unused
      // var elementId = this.$refs.el;
      // this.$router.push('/main')
      var calculated_result = Math.floor(Math.random() * 10)+1; 
      var events = this.character.events || [];
      var statuses = this.character.statuses || [];
      var encounters = this.character.encounters || [];
      var journey_days = this.character.days || 0;
      var character_location = this.character.location;
      var character_locations = this.character.locations;
      
      // LEGACY check nad remove
      //var next_location = this.nextLocation;
      // var next_location_event_index = 0;
      
      // Update Event with action
      var index = 0;
      while(index < events.length){ 
        // Goes through events to find current event
        if(typeof(events[index]['createdOn'])!== 'undefined' && events[index]['createdOn'] == event_time){
          console.log('time defined')
          // Checks to see if attempt needs to be updated
          if(typeof(events[index]['attempt'])== 'undefined'){
            console.log('undefined attempt')
            events[index]['attempt'] = attempt;
            events[index]['calculated_result'] = calculated_result; 
            
            // In not attempt than it is the end result
          }else{

            events[index]['result'] = result;
            if(events[index]['calculated_result'] < 7){
              statuses.push({'name':'injured','type':'injury','penalty':-2,'stat':'tough'})
            }else if (events[index]['calculated_result'] < 10){
              statuses.push({'name':'fatigued','type':'condition','penalty':-1,'stat':'tough'})
            }
            //LEGACY set location
            //
           // var check_location = result.split(' ');
            
            //Check to see if ther is a new location the player has requested
            // check_location.forEach(function (item) {
             
            //     var defined = item.split(/(?=[A-Z])/);
            //     var check = defined.join(" ")
            //     item = check.replace(/([a-z])([A-Z])/g, '$1 $2');
            //     item = item.replace(/[^a-zA-Z $ @ # & %]/g, "");
            //    if(item.includes('@')){
            //       console.log('location to go!')
            //       var character_location_name = item.replace('@','');
            //       console.log('check location')
            //       console.log(item.replace('@',''));
            //       console.log(character_location_name);

            //       //go through the locations to find if one is selected, if So set it
            //       character_locations.forEach(function (item) {
            //       if(item['name'] && item['name'].toLowerCase() == next_location.toLowerCase()){
            //         console.log('success!!! on hook!')
            //         var next_event_location = item;
            //         console.log('would go here');
            //         console.log(next_event_location)
            //         // next_location_event_index  = index;

            //       }
            //       });
            //     }
            // });

              // Get new event
              var days = Math.floor(Math.random() * 6)+1;
              journey_days = journey_days+days;

              var gameTime = this.$moment(event_time.seconds).add(days, 'days').toDate();
              var createdOn = new Date();
              var next_event = {}
              this.character.encounters.forEach(function (encounter){
                console.log('checking encounters')
                console.log(encounter)
                if('next' in encounter && encounter['next']){
                  console.log('found encounter to set');
                  encounter['next'] = false;
                  encounter['chosen'] = true;
                  next_event['location'] = {'name': encounter['location']};
                  next_event['rumor'] = encounter['rumor'];
                  next_event['type'] = encounter['type'];
                  next_event['twist'] = encounter['twist'];
                }
              });
              next_event['gameTime'] = gameTime
              next_event['createdOn'] = createdOn
              // TODO fix preambles
              var preamble='You are a Game Master in a roleplaying game, a Character has asked you to help setup a new scene with a brief 50 word description.';
              preamble = preamble+' The character is arriving at '+next_event['location']['name']+
              'They heard rumors of '+next_event['rumor']
              var postamble = 'Here is the last text provided by the player to provide some more context.';
              // Todo wire up context
              var context = attempt+''+result;
              var ask = preamble+postamble+context;
              console.log(ask)
              //TODO remove waitForScene
              this.waitForScene = true
              await this.setTheScene(ask);

              var description = this.dungeonBuddyScene || 'This looks familiar...';
              next_event['description'] = description                        
              
              // next_event['location'] = next_event_location;
              next_event['gameTime'] = gameTime;
              next_event['createdOn']= createdOn;
              events.push(next_event);
            }
            
          }
          index++;
        
        }
        // NPCS and Creatures need to be unset
        this.clearActive('creatures')
        this.clearActive('npcs')
        var creatures = this.character.creatures;
        var npcs = this.character.npcs;

        fb.charactersCollection.doc(this.$route.params.id).update({
          locations:character_locations,
          location:character_location,
          encounters:encounters,
          events: events,
          statuses:statuses,
          journey_days:journey_days,
          npcs:npcs,
          creatures:creatures,
        }).then(ref => {
          console.log(ref)
          this.character.events = events
          this.ActionForm.attempt = '';
          this.ActionForm.description = '';
          this.ActionForm.result='';
          
        }).catch(err => {
          console.log(err)
        })

      }
    }
  }
</script>     
