<template>
  <div class="col-xs-12 col-md-4 card" style="margin-bottom: 60px;">
        <h4 style="margin-top: 20px;" v-on:click.prevent="toggleMarquee()" ><img v-if="creature.src" class="small-image" :src="creature.src">
        <img v-else class="small-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Creature.jpg">{{creature.name || ''}} - creature</h4>
    <div class="Creature-card">
      
      <div class="card-body card-info">
        <div v-if="showMarquee" class="marquee">
          <img v-if="creature.src" class="card-image" :src="creature.src">
          <img v-else class="card-image" src="https://storage.googleapis.com/rpg-framework-a73be.appspot.com/Shattered_Creature.jpg" alt="Card image cap">
        </div>
        <div>
        <br>
        <h6>Background </h6><hr>
        <div style="text-align: left;">{{creature.background}}</div>
        <br><br>
        <h6>Apperance</h6><hr>
        <div style="text-align: left;">{{creature.appearance}}</div>
        <br><br>
        <h6>Personality</h6><hr>
        <div style="text-align: left;">{{creature.personality}}</div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
   //removed
    export default {
       props: {
          creature: {
            type: Object,
            default: () => {}
          }
        },
         data() {
            return {
                updatingcreature: false,
                showMarquee:false,
              }
            },
              methods: {
                toggleMarquee(){
            this.showMarquee = !this.showMarquee;
          }
                
        }
      }
</script>